<template>
    <div class="app">
        <router-view />
    </div>
</template>
<script>

export default({
  name: "auth-layout",
  mounted() {
  },
  methods: {
    
  },
});
</script>