<template>
    <div>
        <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center" v-if="isLoadingPage">
            <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
            <h2 class="text-center text-white text-xl font-semibold">Loading...</h2>
            <p class="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
        </div>
        <div class="mx-auto 2xl:max-w-7xl max-w-7xl md:px-12 px-8 py-5 pt-32  py-32 mt-36" v-else>
            <div wire:loading class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center" v-if="isLoading">
                <div class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
                <h2 class="text-center text-white text-xl font-semibold">Loading...</h2>
                <p class="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
            </div>
            <div class="grid gap-4 md:grid-cols-1 lg:gap-8 xl:grid-cols-3">
                <div class="flex grid gap-8">
                    <div>
                        <img class="h-auto max-w-full rounded-lg" src="../assets/images/hero-content.png" alt="">

                        <div class="grid grid-cols-3 gap-4 mt-5">
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="../assets/images/hero-content.png" alt="">
                            </div>
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="../assets/images/hero-content.png" alt="">
                            </div>
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="../assets/images/hero-content.png" alt="">
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div class="max-w-sm">
                    <div class="px-2">
                        <div class="font-bold text-black text-2xl">{{ selectedProductPrices.name }}</div>
                    </div>

                    <div class="px-2 pt-2 pb-2 mt-2">
                        <div class="whitespace-no-wrap text-sm leading-5 text-black-500">
                            <div class="flex">
                                <img src="../assets/images/time_icon.png" alt="" class="mr-2"/>
                                <span class="ml-5">{{this.product.currency_code}}.{{ formatPrice(selectedProductPrices.price) }} / {{selectedProductPrices.term}}</span>
                            </div>
                            
                        </div>
                        
                        <div class="whitespace-no-wrap text-sm leading-5 text-black-500 mt-2">
                            <div class="flex">
                                <img src="../assets/images/icon_location.png" alt="" class="mr-2"/>
                                <span class="ml-5">{{location.name}}</span>
                               
                            </div>
                            
                           
                        </div>

                        <div class="whitespace-no-wrap text-sm leading-5 text-black-500 mt-2">
                            <div class="flex">
                                <img src="../assets/images/icon_user.png" alt="" class="mr-2"/>
                                <span class="ml-5">{{ selectedProductPrices.sqm }} sqm</span>
                               
                            </div>
                           
                           
                        </div>
                    </div>

                    <div class="px-2 pt-5 pb-2 mt-5" v-if="product.desc">
                        <div class="text-black text-base">Deskripsi</div>

                        <div class="pt-2">
                            <p class="text-black font-normal text-justify`">
                                {{ product.desc }}
                            </p>
                        </div>
                    </div>
                </div>


                <div class="w-full max-w-lg p-4 bg-gray-200 border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-200 dark:border-gray-200">
                    <h5 class="text-base font-bold dark:text-black text-center">Pilih Tanggal</h5>
                        
                        <div class="mt-4">
                            <label for="helper-radio" class="block mb-2 font-normal dark:text-black">Mulai Tanggal</label>
                            <input  datepicker type="datetime-local" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" v-model="form.started_at" @change="changeDate(selectedProductPrices.term, 'ended_at')">
                        </div>

                        <div class="mt-4">
                            <label for="helper-radio" class="block mb-2 font-normal dark:text-black">Jangka Waktu</label>
                            <input v-model="form.length_of_term" type="number" min="1" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Jangka waktu" @change="changeDate(selectedProductPrices.term, 'ended_at')">
                        </div>


                        <div class="mt-4">
                            <label for="helper-radio" class="block mb-2 font-normal dark:text-black">Selesai</label>
                            <input  datepicker type="datetime-local" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" v-model="form.ended_at" @change="changeDate(selectedProductPrices.term, 'start')" >
                        </div>

                       

                 

                        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700">

                        <div class="m-auto flex justify-between items-center">
                            <div class="ml-2 text-sm">
                                <label for="helper-radio" class="font-normal dark:text-black">Subtotal</label>
                            </div>
                            <div class="flex items-center h-5 mt-2 mb-2">
                               <p class=" text-base">{{this.product.currency_code}}.{{ formatPrice(total_price) }}</p>
                            </div>
                           
                        </div>

                        <div class="m-auto flex justify-between items-center">
                            <div class="ml-2 text-sm">
                                <label for="helper-radio" class="font-normal dark:text-black">Tax</label>
                            </div>
                            <div class="flex items-center h-5 mb-2">
                               <p class=" text-base">{{this.product.currency_code}}.{{ formatPrice(form.total_tax) }}</p>
                            </div>
                           
                        </div>

                        <div class="m-auto flex justify-between items-center mt-6">
                            <div class="ml-2 text-sm">
                                <label for="helper-radio" class="font-bold dark:text-black">Grandtotal</label>
                            </div>
                            <div class="flex items-center h-5 mb-2">
                               <p class="font-bold text-base">{{this.product.currency_code}}.{{ formatPrice(grand_total) }}</p>
                            </div> 
                        </div>

                        <div class="mt-4">
                            <label for="helper-radio" class="block mb-2 font-normal dark:text-black">Kode Promo</label>
                            <input v-model="form.reference_code" type="text" class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Referral code">
                        </div>

                        <div class="m-auto flex justify-between items-center mt-5" v-if="isAvail == false">
                            <button class="bg-red-700 hover:bg-700 text-white font-normal py-4 px-4 rounded-full text-sm mr-1 mb-2 w-full" @click="roomCheckAvailability">
                                <svg aria-hidden="true" role="status" class="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="isLoadingCheck">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"/>
                                </svg>
                                Check Availability
                            </button>
                        </div>

                        <div class="m-auto flex justify-between items-center mt-5" v-else>
                            <button class="bg-red-700 hover:bg-700 text-white font-normal py-4 px-4 rounded-full text-sm mr-1 mb-2 w-full" @click="bookRoom">
                                Book Now
                            </button>
                        </div>


                </div>

            </div>
        </div>

        <div class="mx-auto 2xl:max-w-7xl max-w-7xl md:px-12 px-8 py-5">
            <div class="mx-auto text-left">
              <p class="text-black tracking-tight font-display text-2xl font-bold">Facilities</p>
            </div>
            <div class="grid pt-6 text-left border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
              <div>
                  <div class="mb-10">
                      <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                          <img src="../assets/images/leading_element.png" alt="" class="mr-2">
                          Fully Air - Conditoner
                      </h3>
                  </div>

                  <div class="mb-10">
                        <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                            <img src="../assets/images/leading_element.png" alt="" class="mr-2">
                            High Speed Wierless Internet
                        </h3>
                    </div>

                    <div class="mb-10">
                        <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                            <img src="../assets/images/leading_element.png" alt="" class="mr-2">
                            Audio Visual Equipment
                        </h3>
                    </div>

                    <div class="mb-10">
                        <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                            <img src="../assets/images/leading_element.png" alt="" class="mr-2">
                            User Friendly Video Conference Facilities
                        </h3>
                    </div>
                 
              </div>
              <div>
                <div class="mb-10">
                    <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                        <img src="../assets/images/leading_element.png" alt="" class="mr-2">
                        Fully Air - Conditoner
                    </h3>
                </div>

                <div class="mb-10">
                      <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                          <img src="../assets/images/leading_element.png" alt="" class="mr-2">
                          High Speed Wierless Internet
                      </h3>
                  </div>

                  <div class="mb-10">
                      <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                          <img src="../assets/images/leading_element.png" alt="" class="mr-2">
                          Audio Visual Equipment
                      </h3>
                  </div>

                  <div class="mb-10">
                      <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                          <img src="../assets/images/leading_element.png" alt="" class="mr-2">
                          User Friendly Video Conference Facilities
                      </h3>
                  </div>
              </div>
            </div>
         </div>

         <div class="mx-auto 2xl:max-w-7xl max-w-7xl md:px-12 px-8 py-5">
            <div class="mx-auto text-left">
              <p class="text-black tracking-tight font-display text-2xl font-bold">FAQ</p>
            </div>
            <div class="grid pt-6 text-left border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
              <div>
                  <div class="mb-10">
                      <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                          <img src="../assets/images/icon-button-toggleable.svg" alt="" class="mr-2">
                          Proses Booking?
                      </h3>
                      <p class="text-gray-500 dark:text-gray-400">Booking dapat dilakukan secara online dengan membuat akun pada website Creative-Hub kemudian customer melakukan reservasi dan mengisi informasi yang diperlukan.</p>
                  </div>
                 
              </div>
              <div>
                <div class="mb-10">                        
                  <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                      <img src="../assets/images/icon-button-toggleable.svg" alt="" class="mr-2">
                      Proses Pembayaran?
                  </h3>
                    <p class="text-gray-500 dark:text-gray-400">Setelah melakukan reservasi, customer akan diarahkan pada pilihan bayar dan mendapatkan kode bayar. Pembayaran dapat melalui Mobile Banking, ATM, atau ecommerce dan retail yang bekerja sama.</p>
                </div>
                  
              </div>
            </div>
          </div>
    </div>
</template>
<style>
.loader {
	border-top-color: #3498db;
	-webkit-animation: spinner 1.5s linear infinite;
	animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

</style>

<script>
import AuthService from "@/services/auth.service";
import productService from "@/services/product.service";
import roomService from "@/services/room.service";
import { useRoute } from 'vue-router';
import moment from 'moment';
import Swal from "sweetalert2";
export default {
  name: "product-room-page",
  data() {
    return {
        isAvail: false,
        isLoadingPage:true,
        isLoading:false,
        isLoadingCheck: false,
        tenant:{},
        location_id:'',
        productId:'',
        selectedProductPricesId:'',
        product: {},
        selectedProductPrices:{},
        productPrices:[],
        productIncludes:[],
        includeSalesOrders:[],
        location:{},
        room:{},
        tax:0,
        service_charge:0,
        total_cost:0,
        total_price:0,
        grand_total:0,
        quantity:1,
        half_day_length:4,
        depreciation_perice:0,
        form:{
            checkout_with_xendit : "Y",
            tenant_id : 1,
            location_id : 1,
            customer_id : '',
            contact_id : '',
            emergency_contact_id : null,
            primary_product_id : 1,
            code :'',
            is_inquiry : false,
            has_contract : true,
            is_renewal : false,
            status : "posted",
            renewal_status : "on renewal",
            started_at : '',
            ended_at : '',
            signed_at : '',
            term : "month",
            term_of_payment : 'annually',
            term_notice_period : 3,
            tax_percentage : 11,
            length_of_term : 1,
            deposit:0,
            total_cost : 0,
            total_price : 0,
            total_discount : 0,
            total_tax : 0,
            currency_code : "IDR",
            sales_order_details:[],
            reference_code:''
        }
        
    };
  },
  created() {
        // setInterval(this.getNow, 0);
    },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  async mounted() {
    this.getNow();

    AuthService.getDataTenant().then(
        (response) => {
          var data = response.data;
          this.tenant = data;
          this.form.tenant_id =  data.id;
          this.getLocation(data.id, this.location_id);
         
          
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
    );


    const route = useRoute();  
    let router = await route;

    var data = router.query;

    if(this.currentUser)
    {

    roomService.getCodeSalesOrder().then(
        (response) => {
        var data = response.data;
        this.form.code = data.data;
        
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
    );
    
        this.form.customer_id =  this.currentUser.user.person.customer.id;
        this.form.contact_id = this.currentUser.user.person.customer.id;
        
    }

    if(data){
        
        if(data.productId){
            this.location_id = this.$CryptoJS.AES.decrypt(
            data.location_id,
            "123#$%"
            ).toString(this.$CryptoJS.enc.Utf8);

            this.form.location_id = this.location_id;
        }

        if(data.productId){
            this.productId = this.$CryptoJS.AES.decrypt(
            data.productId,
            "123#$%"
            ).toString(this.$CryptoJS.enc.Utf8);
        
        }

        if(data.selectedProductPrices){
            this.selectedProductPricesId = this.$CryptoJS.AES.decrypt(
            data.selectedProductPrices,
            "123#$%"
            ).toString(this.$CryptoJS.enc.Utf8);
            
        }

       

       
        
    
    }
   
   

    
    
  },
  methods:{
    getNow() {
        const today = new Date();
        const time = today.getHours() + ":" + today.getMinutes();
        const dateTime = moment().format('YYYY-MM-DD')+'T'+moment(time, "HH:mm:ss").format('HH:mm');
       
        this.form.started_at = dateTime;
        this.form.length_of_term = 1;
        this.changeDate(this.selectedProductPrices.term,'ended_at');
        

    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getLocation(tenantId, locationId){
        productService.getDataLocationById(tenantId,locationId).then(
        (response) => {
            var data = response.data.data;
            this.location = data;
            this.getRoom(this.tenant.id, this.productId);
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        );
    },
    async getRoom(tenantId, productId){
      productService.getDataProductsById(tenantId,productId).then(
        (response) => {
          var data = response.data.data;
          this.product = data;
         
            var rooms = data.product_prices;
            var totalTaxInclude = 0;
            var totalServiceChargeInclude = 0;

            this.productPrices.splice(0);
            this.productIncludes.splice(0);
            for (let i = 0; i < rooms.length; i++) {
              if (data.has_room == 1) {
                if (this.location_id == rooms[i].room.location_id) {
                    this.productPrices.push(
                      {
                      "id": rooms[i].id,
                      "tenant_id" : rooms[i].tenant_id,
                      "product_id" : rooms[i].product_id,
                      "asset_type_id" : rooms[i].asset_type_id,
                      "room_id" : rooms[i].room_id,
                      "area_id" : rooms[i].area_id,
                      "has_quantity" : rooms[i].has_quantity,
                      "has_term" : rooms[i].has_term,
                      "has_complimentary": rooms[i].has_complimentary,
                      "term": rooms[i].term,
                      "item": rooms[i].item,
                      "name": rooms[i].name,
                      "currency_code": rooms[i].currency_code,
                      "default_quantity": rooms[i].default_quantity,
                      "price": rooms[i].price,
                      "desc": rooms[i].desc,
                      "sqm": rooms[i].room.sqm
                      }
                    );
                  }
              }else{
                this.productPrices.push(
                      {
                      "id": rooms[i].id,
                      "tenant_id" : rooms[i].tenant_id,
                      "product_id" : rooms[i].product_id,
                      "asset_type_id" : rooms[i].asset_type_id,
                      "room_id" : rooms[i].room_id,
                      "area_id" : rooms[i].area_id,
                      "has_quantity" : rooms[i].has_quantity,
                      "has_term" : rooms[i].has_term,
                      "has_complimentary": rooms[i].has_complimentary,
                      "term": rooms[i].term,
                      "item": rooms[i].item,
                      "name": rooms[i].name,
                      "currency_code": rooms[i].currency_code,
                      "default_quantity": rooms[i].default_quantity,
                      "price": rooms[i].price,
                      "desc": rooms[i].desc,
                      "sqm": rooms[i].room.sqm
                      }
                    );
              }
              
              
            }

            for (var i = 0; i < data.product_includes.length; i++) {
                var price = data.product_includes[i].price;
                var service_charge = price * this.tenant.service_charge_percentage / 100;
                var tax = (parseFloat(price) + parseFloat(service_charge)) * this.tenanttax_percentage / 100;

                var newObjectInclude = {
                    'other_product_id': data.product_includes[i].other_product_id,
                    'asset_type_id': data.product_includes[i].asset_type_id,
                    'room_id': data.product_includes[i].room_id,
                    'complimentary_id': data.product_includes[i].complimentary_id,
                    'name': data.product_includes[i].name,
                    'price': data.product_includes[i].price,
                    'cost': data.product_includes[i].cost,
                    'quantity': data.product_includes[i].quantity,
                    'is_calculated_to_order' : data.product_includes[i].is_calculated_to_order,
                    'has_tax' : data.product_includes[i].has_tax,
                    'has_service_charge' : data.product_includes[i].has_service_charge
                }

                this.productIncludes.push(newObjectInclude);

                if (data.product_includes[i].is_calculated_to_order) {
                    (data.product_includes[i].has_tax) ? totalTaxInclude += tax : totalTaxInclude;
                    (data.product_includes[i].has_service_charge) ? totalServiceChargeInclude += service_charge : totalServiceChargeInclude;
                }
            }

            if (totalServiceChargeInclude > 0) {
                this.service_charge = totalTaxInclude;
            
            }

            if (totalTaxInclude > 0) {
                this.tax = totalTaxInclude;
            }
            this.setRoom(this.selectedProductPricesId);
        
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    setRoom(id){
        
        for (let i = 0; i < this.productPrices.length; i++) {
            if(this.productPrices[i].id == id){
                this.selectedProductPrices = {
                    "product_id" : this.productPrices[i].product_id,
                    "complimentary_id" : "",
                    "customer_complimentary_id" : "",
                    "asset_type_id" : this.productPrices[i].asset_type_id,
                    "asset_id" : "",
                    "room_id" : this.productPrices[i].room_id,
                    "name" :  this.productPrices[i].item +'-'+ this.productPrices[i].name,
                    "type" : "charged",
                    "has_complimentary" : this.productPrices[i].has_complimentary,
                    "has_term" : this.productPrices[i].has_term,
                    "is_repeated_in_term" : false,
                    "term" : this.productPrices[i].term,
                    "repeated_term" : "no term",
                    "has_quantity" : this.productPrices[i].has_quantity,
                    "quantity" : 1,
                    "total_use_of_complimentary" : 0,
                    "cost" : 0,
                    "price" : this.productPrices[i].price,
                    "discount" : 0,
                    "service_charge" : this.service_charge,
                    "tax" : this.tax,
                    "sqm" : this.productPrices[i].sqm
                };

                
            }
        }
        this.isLoadingPage = false;
       
        this.countPrice();
        
    },
    loadIncludesSalesOrder(){
        var total_cost = 0;
        for (var i = 0; i < this.includeSalesOrders.length; i++) {
            this.form.total_cost = total_cost + (this.includeSalesOrders[i].cost * this.includeSalesOrders[i].quantity)
        }
    },
    // addComplimentary(parsingId = null, startedAt = null, lengthOfTerm = null, endedAt = null, quantity = null)
    // {
    //     var id = (parsingId != null) ? parsingId : $('#complimentary_id option:selected').val();

    //     (startedAt == null) ? startedAt = $('#complimentary_started_at').val() : null;

    //     (lengthOfTerm == null) ? lengthOfTerm = $('#complimentary_length_of_term').val() : null;

    //     (endedAt == null) ? endedAt = $('#complimentary_ended_at').val() : null;

    //     (quantity == null) ? quantity = 1 : null;

    //     $('.invalid-feedback').html('')

    //     $('#loadOverlay8').show()

    //     $('#btnAddComplimentary').attr('disabled',true);

    //     if(id){
    //         var url = "{{ url('api/complimentaries/') }}/"+id;

    //         $.ajax({
    //             url: url,
    //             type: 'GET',
    //             headers: {
    //                 'Accept':'application/json',
    //                 'Content-Type':'application/x-www-form-urlencoded',
    //                 'Authorization':'Bearer ' + token,
    //             },
    //             success: function(data) {
    //                 var data = data.data;

    //                 if(data.product){
    //                     var newObject = {
    //                         'complimentary_id' : id,
    //                         'product_id' : data.product_id,
    //                         'complimentary_name' : data.name,
    //                         'product_name' : data.product.name,
    //                         'name' : data.product.name,
    //                         'type': 'complimentary',
    //                         'has_term' : data.has_term,
    //                         'is_repeated_in_term' : data.is_repeated_in_term,
    //                         'term' : data.term,
    //                         'repeated_term' : data.repeated_term,
    //                         'started_at' : startedAt,
    //                         'length_of_term' : lengthOfTerm,
    //                         'ended_at' : endedAt,
    //                         'quantity' : quantity,
    //                     }

    //                     var is_status = false

    //                     if(data.has_term != 1 || data.term != "no term"){
    //                         if($('#complimentary_length_of_term').val() != 0 || $('#complimentary_length_of_term').val() != ""){
    //                             is_status = true

    //                             if($('#complimentary_started_at').val() != null || $('#complimentary_ended_at').val() != null){
    //                                 is_status = true
    //                             }else{
    //                                 is_status = false
    //                                 $('#btnAddComplimentary').attr('disabled',false);
    //                                 $('#complimentary_started_at').html("{{__('label.you_have_to_select_the_started_at')}}")
    //                                 $('#complimentary_ended_at').html("{{__('label.you_have_to_select_the_ended_at')}}")
    //                             }
    //                         }else{
    //                             is_status = false
    //                             $('#btnAddComplimentary').attr('disabled',false);
    //                             $('#complimentary_length_of_term_error_message').html("{{__('label.you_have_to_input_the_length_of_term')}}")
    //                         }

    //                         if(is_status){
    //                             complimentarySalesOrders.push(newObject);

    //                             loadComplimentarySalesOrder();
    //                         }else{
    //                             $('#loadOverlay10').hide()
    //                         }

    //                     }else{
    //                         $('#loadOverlay10').hide()
    //                     }
    //                 }
    //             }
    //         });
    //     }else{
    //         $('#loadOverlay8').hide()
    //         $('#complimentary_error_message').html("{{__('label.you_have_to_select_the_complimentary')}}")
    //     }
    // },
    countPrice(){
        var price = 0;

        var service_charge = 0;

        var tax =  0;


        var quantity = this.quantity;
        
        var discount = 0;
        
        var length_of_term = this.form.length_of_term;
        var total_use_of_complimentary = 0;
        price =  this.selectedProductPrices.price * quantity * (length_of_term - total_use_of_complimentary);

        if (this.tenant.service_charge_percentage > 0 && service_charge > 0) {
            service_charge = (parseFloat(price) - parseFloat(discount)) * this.tenant.service_charge_percentage / 100;
            tax = (service_charge * 100) / 100;
        }

        
        tax = (parseFloat(price) - parseFloat(discount) + parseFloat(service_charge)) * this.tenant.tax_percentage / 100;

        if (total_use_of_complimentary > length_of_term) {
            total_use_of_complimentary = length_of_term;
        }

        var total_price = (parseFloat(price) - parseFloat(discount) + parseFloat(service_charge));
        var grand_total = (parseFloat(price) - parseFloat(discount) + parseFloat(service_charge) + parseFloat(tax));
        
    
        
        this.total_price = price;
        this.form.total_price = total_price;
        this.tax = tax;
        this.form.total_tax = tax;
        this.grand_total = grand_total;
    },
    changeDate(term, targetIdChange){
        this.autoSetupDateTime(this.form.started_at, targetIdChange, term, this.form.length_of_term, true, 'datetime', true);
        this.isAvail = false;
    },
    autoSetupDateTime(sourceDate, targetIdChange, term, lengthOfTerm, isAddition=true, format='date', isReturn = false)
    {
        var currentDate = new Date(sourceDate);

        var targetDate = new Date(sourceDate);

        var factor = 1;

        lengthOfTerm = parseInt(lengthOfTerm)
        switch (term) {
            case 'half day':
                factor = this.half_day_length * 60 * 60 * 1000;

                targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
                break;
            case 'hour':
                factor = 60 * 60 * 1000;

                targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
                break;
            case 'day':
                factor = 24 * 60 * 60 * 1000;

                targetDate = (isAddition) ? new Date(parseInt(currentDate.getTime() + lengthOfTerm * factor)) : new Date(parseInt(currentDate.getTime() - lengthOfTerm * factor));
                break;
            case 'month':
                var newYear = parseInt(currentDate.getFullYear());
                var newMonth = parseInt(currentDate.getMonth());
                var newDate = currentDate.getDate() - 1;

                if (isAddition) {
                    newMonth = newMonth + lengthOfTerm;

                    if (newMonth > 12) {
                        newYear = newYear + (Math.floor(newMonth / 12));
                        newMonth = newMonth % 12;
                    }
                } else {
                    newMonth = newMonth - lengthOfTerm;

                    if (newMonth < 0) {
                        newMonth = newMonth * -1;

                        newYear = newYear - (Math.floor(newMonth / 12));
                        newMonth = newMonth % 12;
                    }
                }
                
                newDate = (newDate > 30 && (newMonth + 1) % 2 === 0) ? 30 : newDate;

                console.log(newDate);

                if (newDate > 28 && (newMonth + 1) == 2) {
                    newDate = (newYear % 4 === 0) ? 29 : 28;
                }
                
                targetDate = new Date(newYear, newMonth, newDate);

                if (!isReturn) {
                    factor = 24 * 60 * 60 * 1000;

                    targetDate = (isAddition) ? new Date(parseInt(targetDate.getTime() - 1 * factor)) : new Date(parseInt(targetDate.getTime() + 1 * factor));
                }

                break;
            case 'year':
                targetDate = new Date((isAddition) ? parseInt(currentDate.getFullYear()) + lengthOfTerm : parseInt(currentDate.getFullYear()) - lengthOfTerm, parseInt(currentDate.getMonth()), currentDate.getDate());

                if (!isReturn) {
                    factor = 24 * 60 * 60 * 1000;

                    targetDate = (isAddition) ? new Date(parseInt(targetDate.getTime() - 1 * factor)) : new Date(parseInt(targetDate.getTime() + 1 * factor));
                }

                break;
        }

        if (term != 'custom' && term != 'no term') {
            var yearFormat = targetDate.getFullYear();
            var monthFormat = (targetDate.getMonth() < 9) ? '0'+ parseInt(targetDate.getMonth() + 1) : parseInt(targetDate.getMonth() + 1);
            var dateFormat = (targetDate.getDate() < 10) ? '0'+targetDate.getDate() : targetDate.getDate();
            var hourFormat = (targetDate.getHours() < 10) ? '0'+targetDate.getHours() : targetDate.getHours();
            var minuteFormat = (targetDate.getMinutes() < 10) ? '0'+targetDate.getMinutes() : targetDate.getMinutes()

            if (format == 'date') {
                if (isReturn) {
                        
                    if(this.targetIdChange == 'start'){
                        this.form.started_at =yearFormat+'/'+monthFormat+'/'+dateFormat;
                    }else{
                      
                        this.form.ended_at =yearFormat+'/'+monthFormat+'/'+dateFormat;
                    }
                } else {
                    if(this.targetIdChange == 'start'){
                        this.form.started_at =yearFormat+'/'+monthFormat+'/'+dateFormat;
                    }else{
                      
                        this.form.ended_at = yearFormat+'/'+monthFormat+'/'+dateFormat;
                    }
                    
                }
                
                
            } else {
                if (isReturn) {
                    if(this.targetIdChange == 'start'){
                        this.form.started_at = yearFormat+'-'+monthFormat+'-'+dateFormat+'T'+hourFormat+':'+minuteFormat;
                    }else{
                        this.form.ended_at = yearFormat+'-'+monthFormat+'-'+dateFormat+'T'+hourFormat+':'+minuteFormat;
                    }
                } else {
                    if(this.targetIdChange == 'start'){
                        this.form.started_at = yearFormat+'-'+monthFormat+'-'+dateFormat+'T'+hourFormat+':'+minuteFormat;
                    }else{
                        this.form.ended_at = yearFormat+'-'+monthFormat+'-'+dateFormat+'T'+hourFormat+':'+minuteFormat;
                    }
                }
            }

            
        }

       
        this.countPrice();
    },
    roomCheckAvailability(){
        this.isLoadingCheck = true;
        roomService.getRoomAvail(this.selectedProductPrices.room_id,this.form.started_at,this.form.ended_at).then(
        (response) => {
            this.isAvail =  true;
            this.isLoadingCheck = false;
            Swal.fire({
                text: "Ruangan tersedia",
                icon: "success",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
            });
            this.addProduct();
        },
        (error) => {
            this.isAvail = false;
            this.isLoadingCheck = false;
           Swal.fire({
                text: "Ruangan tidak tersedia diwaktu atau tanggal yang dipilih.",
                icon: "error",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
            });
        }
        );

       
    },
    addProduct(){
        
        if(this.currentUser != null){
            this.form.sales_order_details.splice(0);
            this.includeSalesOrders.splice(0);
            this.form.primary_product_id = this.selectedProductPrices.product_id;
            this.form.signed_at = this.form.started_at;
            this.term = this.selectedProductPrices.term;
            this.form.term = this.selectedProductPrices.term;
            this.form.currency_code = this.product.currency_code;
            this.form.sales_order_details.push({
                "product_id" : this.selectedProductPrices.product_id,
                "complimentary_id" : "",
                "customer_complimentary_id" : "",
                "asset_type_id" : this.selectedProductPrices.asset_type_id,
                "asset_id" : "",
                "room_id" : this.selectedProductPrices.room_id,
                "name" :    this.selectedProductPrices.name,
                "type" : "charged",
                "has_complimentary" : this.selectedProductPrices.has_complimentary,
                "has_term" : this.selectedProductPrices.has_term,
                "is_repeated_in_term" :false,
                "term" : this.selectedProductPrices.term,
                "repeated_term" : "no term",
                "started_at" : this.form.started_at,
                "ended_at" : this.form.ended_at,
                "length_of_term" : this.form.length_of_term,
                "has_quantity" : this.selectedProductPrices.has_quantity,
                "quantity" : 1,
                "total_use_of_complimentary" : 0,
                "cost" : 0,
                "price" : this.selectedProductPrices.price,
                "discount" : 0,
                "service_charge" : this.service_charge,
                "tax" : this.tax / this.form.length_of_term,
                "sqm" : this.selectedProductPrices.sqm
            });

            for (var i = 0; i < this.product.product_includes.length; i++) {
                var price = this.product.product_includes[i].price;
                var service_charge = price * this.tenant.service_charge_percentage / 100;
                var tax = (parseFloat(price) + parseFloat(service_charge)) * this.tenant.tax_percentage / 100;

                if (this.product.product_includes[i].complimentary_id != null) {
                    // this.addComplimentary(this.product.product_includes[i].complimentary_id, this.form.started_at,this.form.length_of_term,this.form.ended_at, this.product.product_includes[i].quantity);
                } else {
                    if (this.product.product_includes[i].is_calculated_to_order == 1) {
                        var newObject = {
                            'product_id': this.product.product_includes[i].other_product_id,
                            'name': this.product.product_includes[i].name,
                            'type': 'charged',
                            'has_term': 0,
                            'has_quantity' : 1,
                            'term': 'no term',
                            'started_at':this.form.started_at,
                            'ended_at': this.form.ended_at,
                            'length_of_term': 1,
                            'quantity': this.product.product_includes[i].quantity,
                            'total_use_of_complimentary' : 0,
                            'cost': 0,
                            'price': this.product.product_includes[i].price,
                            'discount': 0,
                            'service_charge': service_charge,
                            'tax': tax,
                        }

                        this.form.sales_order_details.push(newObject);
                    } else {
                        var newObjectInclude = {
                            'product_id': this.product.product_includes[i].other_product_id,
                            'asset_type_id': this.product.product_includes[i].asset_type_id,
                            'room_id': this.product.product_includes[i].room_id,
                            'name': this.product.product_includes[i].name,
                            'type': 'including',
                            'price': this.product.product_includes[i].price,
                            'service_charge' : (this.product.product_includes[i].has_service_charge) ? this.product.product_includes[i].has_service_charge : 0,
                            'tax' : (this.product.product_includes[i].has_tax) ? tax : 0,
                            'cost': this.product.product_includes[i].cost,
                            'quantity': this.product.product_includes[i].quantity,
                            'started_at': this.form.started_at,
                            'ended_at': this.form.ended_at,
                        }

                        this.includeSalesOrders.push(newObjectInclude);
                    }
                }
            }

            this.loadIncludesSalesOrder();
           
        }else{
            Swal.fire({
                text: "Anda harus login terlebih dahulu.",
                icon: "error",
                customClass: {
                confirmButton: "btn fw-bold btn-danger",
                },
            }).then((result) =>{
            if (result.isConfirmed) {
                this.$router.push({
                  path: '/login',
                  query: {
                    booking: true,
                  },
                });
              }
          });
        }
       
    },
    bookRoom(){
        console.log(this.form);
        // this.isLoading = true;
        // productService.booking(this.form).then(
        // (response) => {
        //     var data = response;
        //     this.isAvail =  true;
        //     this.isLoading = false;
        //     Swal.fire({
        //         text: "Sukses Booking, Segara lakukan pembayaran.",
        //         icon: "success",
        //         customClass: {
        //         confirmButton: "btn fw-bold btn-danger",
        //         },
        //     }).then((result) =>{
        //     if (result.isConfirmed) {
        //         this.$router.push({
        //           path: '/',
        //         });
        //         window.open(data.xendit_invoice_url, '_blank');
        //       }else{
        //         this.$router.push({
        //           path: '/',
        //         });
        //         window.open(data.xendit_invoice_url, '_blank');
        //       }
        //   });
        // },
        // (error) => {
            
        //     this.isAvail = false;
        //    Swal.fire({
        //         text: "Gagal booking, mohon dicoba kembali.",
        //         icon: "error",
        //         customClass: {
        //         confirmButton: "btn fw-bold btn-danger",
        //         },
        //     });
        // }
        // );
    }
  },
};
</script>