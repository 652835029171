import axios from 'axios';
import authHeader from './auth-header';
import {
    Rooms,
    RoomAvailable,
    SalesOrderCode
} from '../utils/config'
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
class RoomService {
    getCodeSalesOrder() {
        return axios.get(SalesOrderCode, { headers: authHeader() });
    }
    getDetailRoom(id) {
        return axios.get(Rooms + '/' + id, { headers: authHeader() });
    }
    getRoomAvail(roomId,startedAt,endedAt) {
        return axios
            .post(RoomAvailable, {
                room_id: roomId,
                started_at: startedAt,
                ended_at: endedAt,
            }, { headers: authHeader() })
            .then(response => {
                var data = response.data.data;

                return data;
            });
    }

}

export default new RoomService();