<template>
    <div class="pt-20">
        <section class="w-full">
            <div class="flex-col xl:flex-row flex justify-between items-center p-20 lg:p-40">
                <div>
                    <div style="width: 100%; height: 100%; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 64px; display: inline-flex">
                        <div style="color: #424242; font-size: 48px; font-weight: 600; line-height: 57.60px; word-wrap: break-word">Location</div>
                        <div style="width: 304px; height: 72px; color: #424242; font-size: 20px;  font-weight: 500; line-height: 24px; letter-spacing: 0.30px; word-wrap: break-word">{{locations.address}}</div>
                    </div>
                </div>
                <div class="mt-10 lg:mt-0">
                    <div><span style="color: #EB282E; font-size: 48px; font-weight: 600; line-height: 48px; word-wrap: break-word">Telpro<br/></span><span style="color: #424242; font-size: 96px; font-weight: 600; line-height: 96px; word-wrap: break-word">Creative Hub</span></div>
                </div>
            </div>
        </section>

        <section class="mx-auto w-ful">
            <img src="../assets/images/hero.png"  class="w-full h-full" alt="">
        </section>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import AuthService from "@/services/auth.service";
import ApiService from "@/services/api.service";
import productService from "@/services/product.service";
import { webUrl } from "../utils/config";
import { CCarousel, CCarouselItem } from "@coreui/vue";
export default {
  name: "Home",
  components: { CCarousel, CCarouselItem },
  data() {
    return {
      url: webUrl,
      tenant: "",
      locations: {},
    };
  },
  computed: {
  
  },
  mounted() {
    AuthService.getDataTenant().then(
      (response) => {
        var data = response.data;
        this.tenant = data;
        this.getLocation(this.tenant.id);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    getLocation(tenantId) {
      ApiService.getDataLocation(tenantId).then(
        (response) => {
          var data = response.data;
          this.locations = data[0];
          this.locationId = data[0].id;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
  },
};
</script>