<template>
  <div>
    <div class="flex items-center isolate pt-10" v-if="category.code == 'CreativeHub'">
      <div class="flex items-center container mx-auto mt-36 px-4 mb-14">
        <video class="w-full h-auto max-w-full border border-gray-200 rounded-lg " autoplay muted controls>
          <source src="../assets/video/hero_video.mp4" type="video/mp4">
        </video>
      </div>

     
    </div>


    <div class="flex items-center container mx-auto mt-36 mb-20" v-else>
      <div class="w-full lg:flex px-4">
        <div class="grid gap-16 md:grid-cols-1 lg:gap-10 xl:grid-cols-2">
          <div v-if="category.picture_path != null">
            <img alt="" class="object-cover w-full rounded-xl" :src="url+category.picture_path" style="height:470px"/>
          </div>
          <div v-else>
            <img alt="" class="object-cover w-full rounded-xl" src="../assets/images/hero-banner.png" style="height:470px"/>
          </div>
        

          <div class=" bg-white rounded-lg pt-10 flex flex-col justify-center leading-normal">
            <div class="mb-8">
              <div class="text-black font-black  text-2xl mb-2">{{category.name}}</div>
              <p class="text-black font-normal">
                {{category.desc }}
              </p>
  
              
              <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mt-8" @click="pushRoute(category.id)">
                Book now
              </button>
            </div>
          </div>
        </div>  
      </div>
      
          
    </div>

  

    <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-10" v-if="category.code == 'Coworking'">
      <div class="mx-auto max-w-xl text-center">
          <p class="text-black tracking-tight font-display text-2xl font-bold">Opsi {{category.name}}</p>
      </div>
      <div class="mx-auto flex grid  gap-4 md:grid-cols-1 lg:grid-cols-3 mt-8">
        <img src="../assets/images/coworking/hero_1.png" alt="" class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
        <img src="../assets/images/coworking/hero_2.png" alt="" class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
        <img src="../assets/images/coworking/hero_3.png" alt="" class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300">
      </div>
    </div>



    <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-10"  :style="category.code == 'CreativeHub' ? '' : 'display:none;' ">
      <div class="mx-auto max-w-xl text-center">
        <p class="text-black tracking-tight font-display text-2xl font-bold">Opsi {{category.name}}</p>
        <p class="mt-2 text-gray-600 font-normal leading-5">
          {{category.desc}}
        </p>
    </div>

      <CCarousel controls indicators :transition="'slide'" :interval="2000" :wrap="true" class="mt-10">
        <CCarouselItem>
          <img src="../assets/slider/CreativeHUB/img_Creativehub.png" class="d-block w-100" alt="...">
        </CCarouselItem>
        <CCarouselItem>
          <img src="../assets/slider/CreativeHUB/img_Creativehub-1.png" class="d-block w-100" alt="...">
        </CCarouselItem>
        <CCarouselItem>
          <img src="../assets/slider/CreativeHUB/img_Creativehub-2.png" class="d-block w-100" alt="...">
        </CCarouselItem>
      </CCarousel>
      
      

     
     
    </div>
  

    <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-10 bg-white dark:bg-gray-800 dark:border-gray-700 mx-5 md:mx-auto"  v-if="category.code == 'CreativeHub'">
      <div class="mx-auto flex grid gap-16 md:grid-cols-1 lg:gap-26 xl:grid-cols-2 mt-10">
        <img alt="" class="object-cover w-full rounded-xl" src="../assets/images/hero_asset.png" style="height:470px"/>
        <div>
          <p class="text-black tracking-tight font-display text-2xl font-bold mb-10">What Will You Get</p>
          <div class="mb-10">
              <h4 class="flex items-center mb-4 text-md font-medium text-black dark:text-black">
                  <img src="../assets/images/checked-red.gif" alt="" class="mr-2" width="40" height="40">
                  Peralatan terbaik
              </h4>
          </div>

          <div class="mb-10">
                <h4 class="flex items-center mb-4 text-md font-medium text-black dark:text-black">
                    <img src="../assets/images/checked-red.gif" alt="" class="mr-2" width="40" height="40">
                    Operator berpengalaman
                </h4>
            </div>

            <div class="mb-10">
                <h4 class="flex items-center mb-4 text-md font-medium text-black dark:text-black">
                    <img src="../assets/images/checked-red.gif" alt="" class="mr-2" width="40" height="40">
                    Talenta Terlatih
                </h4>
            </div>

            <div class="mb-10">
                <h4 class="flex items-center mb-4 text-md font-medium text-black dark:text-black">
                    <img src="../assets/images/checked-red.gif" alt="" class="mr-2" width="40" height="40">
                    Layanan Catering (optional)
                </h4>
            </div>
            <div class="mb-10">
              <h4 class="flex items-center mb-4 text-md font-medium text-black dark:text-black">
                  <img src="../assets/images/checked-red.gif" alt="" class="mr-2" width="40" height="40">
                  Tanpa kerumitan bergerak
              </h4>
          </div>
         
        </div>
      </div>  
      <div class="mt-20">
          <div class="mx-auto">
            <p class="text-black tracking-tight font-display text-2xl font-bold text-center">Facilities</p>
          </div>
          <div class="grid grid-cols-2 pt-6 text-left border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-3 mt-6">
            <div>
                <div class="mb-10">
                    <div class=" mx-auto flex justify-center items-center">
                        <img src="../assets/icon/ic_zoom.svg" alt="" >
                    </div>
                    <div class="mt-2 text-center">
                      <span class="text-lg font-medium text-black"> Professional Zoom Account</span>
                    </div>
                </div>
    
                  <div class="mb-10">
                    <div class=" mx-auto flex justify-center items-center">
                        <img src="../assets/icon/ic_lighting.svg" alt="" >
                    </div>
                    <div class="mt-2 text-center">
                      <span class="text-lg font-medium text-black"> Lighting</span>
                    </div>
                    
                  </div>
               
            </div>
            <div>
              <div class="mb-10">
                <div class=" mx-auto flex justify-center items-center">
                    <img src="../assets/icon/ic_studiobackground.svg" alt="" >
                </div>
                <div class="mt-2 text-center">
                  <span class="text-lg font-medium text-black"> Green Screen</span>
                </div>
              </div>
    
              <div class="mb-10">
                <div class=" mx-auto flex justify-center items-center">
                    <img src="../assets/icon/ic_camera.svg" alt="" >
                </div>
                <div class="mt-2 text-center">
                  <span class="text-lg font-medium text-black"> Full HD Sony Camera X70</span>
                </div>
              </div>
    
               
    
            </div>
            <div>
              <div class="mb-10">
                  <div class=" mx-auto flex justify-center items-center">
                      <img src="../assets/icon/ic_monitor.svg" alt="" >
                  </div>
                  <div class="mt-2 text-center">
                    <span class="text-lg font-medium text-black"> TV Plasma</span>
                  </div>
                  
              </div>
    
                <div class="mb-10">
                  <div class=" mx-auto flex justify-center items-center">
                      <img src="../assets/icon/ic_pc.svg" alt="" >
                  </div>
                  <div class="mt-2 text-center">
                    <span class="text-lg font-medium text-black"> PC With Vmix App</span>
                  </div>
                </div>
    
             
    
            </div>
          </div>
      </div>
    </div>


    <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-10" v-if="category.code == 'CreativeHub'">
      <div class="mx-auto max-w-xl text-center">
          <p class="text-black tracking-tight font-display text-2xl font-bold">{{category.name}} Package</p>
         
      </div>
      <div class="mx-auto flex grid  gap-4 md:grid-cols-1 lg:grid-cols-3 mt-8">
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/event_space/hero_1.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2">
                  Book Now
                </button>
            </div>
          </div>
        </div>
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/event_space/hero_2.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2">
                  Book Now
                </button>
            </div>
          </div>
        </div>
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/event_space/hero_3.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2">
                  Book Now
                </button>
            </div>
          </div>
        </div>  
      </div>
    </div>

    <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-10" v-if="category.code == 'CreativeHub' && paginated.length > 0">
      <div class="mx-auto max-w-xl text-center">
          <p class="text-black tracking-tight font-display text-2xl font-bold">Product</p>
         
      </div>
      <div class="mt-10 grid gap-8 md:grid-cols-2 lg:gap-8 xl:grid-cols-4 ">

        <div  v-for="(detail, index) in paginated" :key="index">
          <div class="max-w-sm rounded overflow-hidden shadow-lg">
            <img class="w-full " src="../assets/images/hero-content.png" alt="Sunset in the mountains"/>
            <div class="px-2 py-4">
                <div class="font-bold text-base text-black truncate">{{detail.item}}</div>
                <div class="font-bold text-base text-black truncate">{{detail.name}}</div>
                <p class="text-gray-700 text-sm" >
                Rp. {{ formatPrice(detail.price) }}
                </p>
            </div>
            
            <div class="px-2 pt-10 pb-5 mt-8">
                <div class="flex items-center float-right mb-2">
                    <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="bookRoom(detail.id)">
                        Lihat Detail
                    </button>
                </div>
                
            </div>
            </div>
          
        </div>
        

        
    </div>
    <div class="max-w-lg mx-auto my-12" v-if="paginated.length > 0">
      <pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
        :has-more-pages="hasMorePages" @pagechanged="showMoreRoom">
      </pagination>
    </div>
    </div>
    

    <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-10" v-if="category.name == 'Virtual Office'">
      <div class="mx-auto max-w-xl text-center">
          <p class="text-black tracking-tight font-display text-2xl font-bold">Opsi {{category.name}}</p>
        
      </div>
      <div class="mx-auto flex grid  gap-4 md:grid-cols-1 lg:grid-cols-3 mt-8">
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/virtual_office/hero_1.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(category.id)">
                  Book Now
                </button>
                
            </div>
          </div>
        </div>
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/virtual_office/hero_2.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(category.id)">
                  Book Now
                </button>
            </div>
          </div>
        </div>
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/virtual_office/hero_3.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(category.id)">
                  Book Now
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-10" v-if="category.name == 'Co-working Space'">
      <div class="mx-auto max-w-xl text-center">
          <p class="text-black tracking-tight font-display text-2xl font-bold">Opsi {{category.name}}</p>
        
      </div>
      <div class="mx-auto flex grid  gap-4 md:grid-cols-1 lg:grid-cols-3 mt-8">
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/coworking/hero_1.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(category.id)">
                  Book Now
                </button>
                
            </div>
          </div>
        </div>
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/coworking/hero_2.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(category.id)">
                  Book Now
                </button>
            </div>
          </div>
        </div>
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/coworking/hero_3.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(category.id)">
                  Book Now
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-10" v-if="category.name == 'Serviced Office'">
      <div class="mx-auto max-w-xl text-center">
          <p class="text-black tracking-tight font-display text-2xl font-bold">Opsi {{category.name}}</p>
         
      </div>
      <div class="mx-auto flex grid  gap-4 md:grid-cols-1 lg:grid-cols-3 mt-8">
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/service_office/hero_1.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(category.id)">
                  Book Now
                </button>
            </div>
          </div>
        </div>
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/service_office/hero_2.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(category.id)">
                  Book Now
                </button>
            </div>
          </div>
        </div>
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/service_office/hero_3.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(category.id)">
                  Book Now
                </button>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-10" v-if="category.name == 'Meeting Room'">
      <div class="mx-auto max-w-xl text-center">
          <p class="text-black tracking-tight font-display text-2xl font-bold">Opsi {{category.name}}</p>
         
      </div>
      <div class="mx-auto flex grid  gap-4 md:grid-cols-1 lg:grid-cols-3 mt-8">
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/meeting_room/hero_1.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(category.id)">
                  Book Now
                </button>
                
            </div>
          </div>
        </div>
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/meeting_room/hero_2.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(category.id)">
                  Book Now
                </button>
            </div>
          </div>
        </div>
        <div class="transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110  duration-300  items-center border-2 border-gray-100 rounded-lg">
          <img src="../assets/images/meeting_room/hero_3.png" alt="">
          <div class="px-2 pt-2 pb-2 mt-2">
            <div class="flex items-center float-right mb-2">
                <button class="bg-red-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2" @click="pushRoute(category.id)">
                  Book Now
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>

   

   <div class="mx-auto 2xl:max-w-7xl max-w-7xl md:px-12 px-8 py-5 mt-10" v-if="category.code != 'CreativeHub'">
    <div class="mx-auto text-center">
      <p class="text-black tracking-tight font-display text-2xl font-bold text-center">Our Facility</p>
      <p class="mt-2 text-black font-normal">Fasilitas-fasilitas pendukung yang disediakan oleh Creative-Hub
        untuk para pelanggan.</p>
    </div>
    <div class="grid pt-6 text-left border-gray-200 md:gap-16 dark:border-gray-700 grid-cols-2 lg:grid-cols-4 mt-6">
      <div>
          <div class="mb-10">
            <div class=" mx-auto flex justify-center items-center">
                <img src="../assets/gif/hero_productivity.gif" alt="">
            </div>
            <div class="mt-2 text-center">
              <span class="text-lg font-medium text-black">
                Fasilitas printer untuk menunjang produktivitas
              </span>
            </div>
          </div>

          <div class="mb-10">
            <div class=" mx-auto flex justify-center items-center">
                <img src="../assets/gif/hero_coffee.gif" alt="">
            </div>
            <div class="mt-2 text-center">
              <span class="text-lg font-medium text-black">
                Pantry yang dilengkapi
                coffee maker,
                microwave, peralatan
                makan & minum, dan
                lainnya
              </span>
            </div>
          </div>
      </div>
      <div>
        <div class="mb-10">
          <div class=" mx-auto flex justify-center items-center">
              <img src="../assets/gif/hero_package.gif" alt="">
          </div>
          <div class="mt-2 text-center">
            <span class="text-lg font-medium text-black">
              Layanan surat menyurat maupun penerimaan paket
            </span>
          </div>
        </div>
        <div class="mb-10">
          <div class=" mx-auto flex justify-center items-center">
              <img src="../assets/gif/parking.png" alt="">
          </div>
          <div class="mt-2 text-center">
            <span class="text-lg font-medium text-black">
              Area parkir yang luas dan aman
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="mb-10">
          <div class=" mx-auto flex justify-center items-center">
              <img src="../assets/gif/hero_connectivity.gif" alt="">
          </div>
          <div class="mt-2 text-center">
            <span class="text-lg font-medium text-black">
              Akses internet dengan kecepatan tinggi
            </span>
          </div>
        </div>
        <div class="mb-10">
          <div class=" mx-auto flex justify-center items-center">
              <img src="../assets/gif/hero_lock.gif" alt="">
          </div>
          <div class="mt-2 text-center">
            <span class="text-lg font-medium text-black">
              Loker yang disediakan
              untuk mendukung
              kenyamanan dan
              keamanan pelanggan
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="mb-10">
          <div class=" mx-auto flex justify-center items-center">
              <img src="../assets/gif/hero_person.gif" alt="">
          </div>
          <div class="mt-2 text-center">
            <span class="text-lg font-medium text-black">
              Akses terhadap
              komunitas dan
              incubator yang dimiliki
              Telkom
            </span>
          </div>
        </div>
        <div class="mb-10">
          <div class=" mx-auto flex justify-center items-center">
              <img src="../assets/gif/hero_map.gif" alt="">
          </div>
          <div class="mt-2 text-center">
            <span class="text-lg font-medium text-black">
              Alamat bisnis dengan
              menggunakan lokasi
              Creative-Hub
            </span>
          </div>
        </div>
      </div>
     
    </div>
  </div>

    <!-- Faq -->
    <div class="mx-auto 2xl:max-w-7xl max-w-6xl md:px-12 px-8 py-5 mt-10">
      <div class="mx-auto text-left">
        <p class="text-black tracking-tight font-display text-2xl font-bold">FAQ</p>
      </div>
      <div class="grid pt-6 text-left border-gray-200 md:gap-16 dark:border-gray-700 md:grid-cols-2">
        <div>
            <div class="mb-10">
                <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
                  <img src="../assets/gif/hero_booking.gif" alt="" width="100" height="100">
                    
                    Proses Booking?
                </h3>
                <p class="text-gray-500 dark:text-gray-400 ml-2 text-justify">Booking dapat dilakukan secara online dengan membuat akun pada website Creative-Hub kemudian customer melakukan reservasi dan mengisi informasi yang diperlukan.</p>
            </div>
           
        </div>
        <div>
          <div class="mb-10">                        
            <h3 class="flex items-center mb-4 text-lg font-medium text-black dark:text-black">
              <img src="../assets/gif/hero_payment.gif" alt="" width="100" height="100">
                Proses Pembayaran?
            </h3>
              <p class="text-gray-500 dark:text-gray-400 ml-2 text-justify">Setelah melakukan reservasi, customer akan diarahkan pada pilihan bayar dan mendapatkan kode bayar. Pembayaran dapat melalui Mobile Banking, ATM, atau e-commerce dan retail yang bekerja sama.</p>
          </div>
            
        </div>
      </div>
    </div>
    <!-- Faq -->
  </div>
    
</template>
<script>
import '@coreui/coreui/dist/css/coreui.min.css'
import productService from "@/services/product.service";
import Pagination from "@/components/Pagination";
import { webUrl } from '../utils/config'
import { CCarousel,CCarouselItem } from '@coreui/vue';

export default {
  name: "service-page",
  components: {  CCarousel,CCarouselItem,Pagination },
  data() {
    return {
      url: webUrl,
      Id:'',
      category:{},
      isLoading: true,
      isProductPrices:false,
      tenant_id: "",
      page: 1,
      totalPages: 10,
      total: 10,
      perPage: 8,
      currentPage: 1,
      hasMorePages: true,
      location_id:1025,
      product: {},
      productPrices:[],
    };
  },
  computed: {
    indexStart() {
      return (this.currentPage - 1) * this.perPage;
    },
    indexEnd() {
      return this.indexStart + this.perPage;
    },
    paginated() {
      
      return this.productPrices.slice(this.indexStart, this.indexEnd);
    },
    dataTenant() {
      return JSON.parse(localStorage.getItem('tenant'));
    },
  },
  mounted() {
    let data = this.$route.query;

   
   
    if(data.category_id){
        this.Id = this.$CryptoJS.AES.decrypt(
        data.category_id,
        "123#$%"
        ).toString(this.$CryptoJS.enc.Utf8);

    }


    productService.getCategoriesById(this.Id,this.dataTenant.id).then(
      (response) => {
        var data = response.data.data;
       this.category = data;
      //  if(data.code == 'CreativeHub'){
      //   this.getProduct(this.dataTenant.id,this.Id,this.location_id,this.page);
      //  }
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    
    
    
    
  },
  methods:{
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getProduct(tenantId,categoryId,locationId,page){
      
      productService.getDataProducts(tenantId,categoryId,locationId,page).then(
        (response) => {
          var data = response.data.data;
          this.getRoom(tenantId,data.data[0].id);
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getRoom(tenantId,productId){
      productService.getDataProductsById(tenantId,productId).then(
        (response) => {
          var data = response.data.data;
        
          this.product = data;
          if(this.location_id != null){
            var rooms = data.product_prices;
            this.productPrices.splice(0);
            for (let i = 0; i < rooms.length; i++) {
              if (data.has_room == 1) {
                if (this.location_id == rooms[i].room.location_id) {
                    this.productPrices.push(
                      {
                      "id": rooms[i].id,
                      "tenant_id" : rooms[i].tenant_id,
                      "product_id" : rooms[i].product_id,
                      "asset_type_id" : rooms[i].asset_type_id,
                      "room_id" : rooms[i].room_id,
                      "area_id" : rooms[i].area_id,
                      "has_quantity" : rooms[i].has_quantity,
                      "has_term" : rooms[i].has_term,
                      "has_complimentary": rooms[i].has_complimentary,
                      "term": rooms[i].term,
                      "item": rooms[i].item,
                      "name": rooms[i].name,
                      "currency_code": rooms[i].currency_code,
                      "default_quantity": rooms[i].default_quantity,
                      "price": rooms[i].price,
                      "desc": rooms[i].desc,
                      }
                    );
                  }
              }else{
                this.productPrices.push(
                      {
                      "id": rooms[i].id,
                      "tenant_id" : rooms[i].tenant_id,
                      "product_id" : rooms[i].product_id,
                      "asset_type_id" : rooms[i].asset_type_id,
                      "room_id" : rooms[i].room_id,
                      "area_id" : rooms[i].area_id,
                      "has_quantity" : rooms[i].has_quantity,
                      "has_term" : rooms[i].has_term,
                      "has_complimentary": rooms[i].has_complimentary,
                      "term": rooms[i].term,
                      "item": rooms[i].item,
                      "name": rooms[i].name,
                      "currency_code": rooms[i].currency_code,
                      "default_quantity": rooms[i].default_quantity,
                      "price": rooms[i].price,
                      "desc": rooms[i].desc,
                      }
                    );
              }
              
              
              }
              this.totalPages  = this.productPrices.length / 8;
              this.total = this.productPrices.length;
            
          }else{
            this.productPrices = data.product_prices;
            this.totalPages  = this.productPrices.length / 8;
              this.total = this.productPrices.length;
          }
          this.isProductPrices = true;
          this.isLoading = false;
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    pushRoute(categoryId){
     
      if(categoryId != null){
        this.$router.push({
          name: "product",
          query: {
            category_id: this.$CryptoJS.AES.encrypt(
              categoryId.toString(),
              "123#$%"
            ).toString()
          },
        }).then((response) => {
          location.reload();
        });
        
      }
      
    },
    bookRoom(id){
      var dataRoom = this.productPrices.find(room => room.id === id);
     
      this.$router.push({
        name: "booking-room",
        query: {
          location_id: this.$CryptoJS.AES.encrypt(
            this.location_id.toString(),
            "123#$%"
          ).toString(),
          productId: this.$CryptoJS.AES.encrypt(
            dataRoom.product_id.toString(),
            "123#$%"
          ).toString(),
          selectedProductPrices: this.$CryptoJS.AES.encrypt(
            id.toString(),
            "123#$%"
          ).toString(),
        }
      }).then((response) => {
        location.reload();
      });
    }
  }
};
</script>