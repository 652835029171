import axios from 'axios';
import { Login, Logout, Profile, Register, Tenant, ForgotPassword, UpdateProfile, updatedPassword,SendOTP,ResetPassword } from '../utils/config'
import authHeader from './auth-header';
class AuthService {

    login(user) {
        return axios
            .post(Login, {
                tenant_id:user.tenant_id,
                email: user.email,
                password: user.password
            },{ headers: authHeader() })
            .then(response => {
                var data = response.data.data;


                return data;
            });
    }

    logout() {
        return axios
            .post(Logout, {}, { headers: authHeader() })
            .then(response => {
                localStorage.removeItem('user');
            });
    }

    register(user) {
        return axios
            .post(Register, {
                tenant_id: user.tenant_id,
                code: user.code,
                email: user.email,
                name: user.name,
                password: user.password,
                password_confirmation: user.password_confirmation,
                identity_type: user.identity_type,
                phone: user.phone,
                registration_type: user.registration_type
            },{ headers: authHeader() })
            .then(response => {
                var data = response.data.data;
                return data;
            });
    }

    getDataProfile() {
        return axios.get(Profile, { headers: authHeader() })
            .then(response => {
                var data = response.data.data;

              

                return data;
            });
    }

    getDataTenant() {
        return axios.get(Tenant, {
         headers: 
            {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                var data = response.data;
                return data;
        });
    }

    requestOTP(user) {
        return axios
            .post(ForgotPassword, {
                tenant_id:user.tenant_id,
                email: user.email,
            },{ headers: authHeader() })
            .then(response => {
                var data = response.data.data;


                return data;
            });
    }

    verifyOTP(user) {
        return axios
            .post(SendOTP, {
                tenant_id:user.tenant_id,
                code: user.code,
            },{ headers: authHeader() })
            .then(response => {
                var data = response.data.data;


                return data;
            });
    }


    updatedProfile(user) {
        return axios
            .post(UpdateProfile, {
                tenant_id:user.tenant_id,
                code:user.code,
                email: user.email,
                name: user.name,
                country: user.country,
                identity_type: user.identity_type,
                identity_number : user.identity_number,
                avatar: user.image,
                phone: user.phone

            },{ headers: authHeader() })
            .then(response => {
                var data = response.data.data;


                return data;
            });
    }


    updatedPassword(user) {
        return axios
            .post(updatedPassword, {
                tenant_id:user.tenant_id,
                email: user.email,
                password: user.password,
                new_password: user.new_password,
                new_password_confirmation: user.new_password_confirmation

            },{ headers: authHeader() })
            .then(response => {
                var data = response.data.data;


                return data;
            });
    }


   resetPassword(user) {
        return axios
            .post(ResetPassword, {
                tenant_id:user.tenant_id,
                email: user.email,
                password: user.password,
                password_confirmation: user.password_confirmation

            },{ headers: authHeader() })
            .then(response => {
                var data = response.data.data;


                return data;
            });
    }
}

export default new AuthService();