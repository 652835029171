<template>
    <div class="bg-white pt-10">
        <section class="p-20">
            <p class="text-black tracking-tight font-display text-2xl font-bold">
                Whats On
            </p>
            <div
                class="mt-10 grid xl:grid-cols-4 lg:xl:grid-cols-4 md:xl:grid-cols-3 sm:xl:grid-cols-1 gap-4"
            >
                <div v-for="item in whatsOn" :key="item.id">
                    <div class="bg-white">
                        <div>
                          <img
                            src="../assets/images/article/image.png"
                            class="rounded-t w-full" v-if="item.picture_path == null"
                          /> 
                          <img
                            :src="url+item.picture_path"
                            class="rounded-t w-full" v-else
                          />
                        </div>
                        <div class="p-2">
                          <article class="text-gray-700 mt-2">{{item.name}}</article>
                          <div class="mt-2 text-sm font-paragraph" v-html="item.content ? item.content.slice(0, 250 - 3) + '...' : ''">
                          </div>
                        </div>
                        <div class="px-2 py-4">
                          <a  class="inline-flex items-center font-second-brand underline cursor-pointer" @click="goToDetail(item.id)">
                            Baca 8 menit
                          </a>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="max-w-lg mx-auto  py-5">
                <pagination :total-pages="totalPages" :total="total" :per-page="perPage" :current-page="currentPage"
                  :has-more-pages="hasMorePages" @pagechanged="showMore">
                </pagination>
              </div>
        </section>
    </div>
</template>
<script>
import AuthService from "@/services/auth.service";
import productService from "@/services/product.service";
import Pagination from "@/components/Pagination";
import { webUrl } from "../utils/config";
export default{
    name: "whats-on-page",
    components: { 
     Pagination 
    },
    data() {
        return{
            url: webUrl,
            page: 1,
            totalPages: 12,
            total: 10,
            perPage: 8,
            currentPage: 1,
            last_page: 1,
            hasMorePages: true,
            whatsOn:[]
        }
    },
    mounted(){
        AuthService.getDataTenant().then(
        (response) => {
            var data = response.data;
            this.tenant = data;
            this.getWhatOn(this.tenant.id);
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        );
    },
    methods: {
        getWhatOn(tenantId) {
        productService.getWebContentWithPaginate(tenantId, "whats_on",this.page,this.totalPages).then(
            (response) => {
            var data = response.data.data;
            this.whatsOn = data.data;
            this.currentPage = data.current_page;
            this.total = data.total;
            this.totalPages = data.last_page;
            this.last_page = data.last_page;
            },
            (error) => {
            this.content =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
            }
        );
        },
        goToDetail(id){
            this.$router
            .push({
                name: "whats-on-detail",
                query: {
                    Id: id,
                }
            })
            .then((response) => {
                location.reload();
            });
        }
    }
}
</script>