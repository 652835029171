<template>
    <div class="pt-10">
        <main class="pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
            <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
                <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
                    <header class="mb-4 lg:mb-6 not-format">
                        <!-- <address class="flex items-center mb-6 not-italic">
                            <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                                <img class="mr-4 w-16 h-16 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-2.jpg" alt="Jese Leos">
                                <div>
                                    <a href="#" rel="author" class="text-xl font-bold text-gray-900 dark:text-white">Jese Leos</a>
                                    <p class="text-base text-gray-500 dark:text-gray-400">Graphic Designer, educator & CEO Flowbite</p>
                                    <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2022-02-08" title="February 8th, 2022">Feb. 8, 2022</time></p>
                                </div>
                            </div>
                        </address> -->
                        <h1 class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">{{whatsOn.name}}</h1>
                        <p class="text-base text-gray-500 dark:text-gray-400"><time pubdate datetime="2022-02-08" title="February 8th, 2022">{{ date(whatsOn.created_at)}}</time></p>
                    </header>
                    <figure>
                        <img src="https://flowbite.s3.amazonaws.com/typography-plugin/typography-image-1.png" alt="" v-if="whatsOn.picture_path == null">
                        <img :src="url+whatsOn.picture_path" alt="" v-else>
                       
                    </figure>

                    <div class="mt-5" v-if="whatsOn.content != null" v-html="whatsOn.content">
                    </div>

                    
                </article>
            </div>
          </main>
    </div>
</template>
<script>
import { useRoute } from 'vue-router';
import AuthService from "@/services/auth.service";
import productService from "@/services/product.service";
import { webUrl } from "../utils/config";
import moment from "moment";
export default{
    name: "whats-on-page",
    data() {
        return{
            url: webUrl,
            id:'',
            whatsOn:{}
        }
    },
    async mounted(){
        const route = useRoute();  
        let router = await route;

        var data = router.query;
        if(data){
            this.id = data.Id;
        }

        AuthService.getDataTenant().then(
        (response) => {
            var data = response.data;
            this.tenant = data;
            this.getWhatOn(this.tenant.id);
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        );
    },
    methods: {
        date: function (date) {
        return moment(date).format("MMMM Do YYYY HH:mm");
        },
        getWhatOn(tenantId) {
        productService.getWebContentById(this.id).then(
            (response) => {
            var data = response.data.data;
            this.whatsOn = data;
            // console.log(data);
            },
            (error) => {
            this.content =
                (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString();
            }
        );
        },
       
    }
}
</script>