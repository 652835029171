<template>
  <div class="flex flex-col md:flex-row h-screen items-center">
    <div class="hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen">
        <img src="../assets/images/bg-login.png" alt="" class="w-full h-full object-cover"/>
    </div>    
    <div class="w-full md:max-w-md lg:max-w-full md:mx-auto md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center justify-center bg-white ">
        <div class="w-full h-100">
            <h1 class="text-xl md:text-2xl font-bold leading-tight mt-12 text-center text-black">Register</h1>
            <form class="mt-6" @submit.prevent="userRegister">
                <label class="block text-black">Name</label>
                <input type="text" name="name" v-model="user.name"   placeholder="Input name" class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required/>
               
                <div class="mt-4">
                  <label class="block text-black">Email</label>
                  <input type="email" name="email" v-model="user.email"   placeholder="Enter Email" class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required />
                </div>

                <div class="mt-4">
                  <label class="block text-black">No. Handphone</label>
                  <input type="number" name="phone" v-model="user.phone"   placeholder="Enter Phone" class="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required />
                </div>


                <div class="mt-4">
                    <label class="block text-black">Password</label>
                    <div class="flex flex-wrap items-stretch w-full relative h-15 items-center">
                      <input :type="type" name="password" v-model="user.password"   placeholder="Enter Password" minLength=8 class="flex-shrink flex-grow flex-auto leading-normal w-px  rounded px-4 py-3  bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required />
                      
                      <span @click="showPassword()"
                      class="absolute inset-y-0 right-0 flex items-center pr-3"
                      >
                      <i  :class="icon"></i>
                      </span>
                     
                    
                    </div>
                  
                </div>


                <div class="mt-4">
                    <label class="block text-black">Password Confirmation</label>
                    <div class="flex flex-wrap items-stretch w-full relative h-15 items-center">
                      <input :type="type" name="password" v-model="user.password_confirmation"   placeholder="Enter Password" minLength=8 class="flex-shrink flex-grow flex-auto leading-normal w-px  rounded px-4 py-3  bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required />
                      
                      <span @click="showPassword()"
                      class="absolute inset-y-0 right-0 flex items-center pr-3"
                      >
                      <i  :class="icon"></i>
                      </span>
                     
                    
                    </div>
                </div>

              
                <button type="submit" class="w-full block bg-red-500 hover:bg-red-400 focus:bg-red-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"> Register
                </button>
            </form>

            <p class="mt-8 text-black">Sudah memiliki akun ?  <router-link :to="{ path: '/login' }"><a href="#" class="text-black hover:text-black font-semibold">Login</a></router-link></p>

            <router-link :to="{ path: '/' }"><button class="btn btn-default text-black hover:text-red font-semibold">Kembali ke Beranda</button></router-link>
        </div>
    </div>

</div>
</template>
<script>
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";
export default {
  name: "register-page",
  data() {
    return {
      loading: false,
      user: {
        code:"",
        tenant_id: "",
        email: "",
        name:"",
        phone: "",
        password: "",
        password_confirmation: "",
        identity_type: "id_cards",
        registration_type: "customer"
      },
      type: "password",
      showOrHide: "Show",
      icon: "fa fa-eye-slash",
      message: "",
      is_verify_view: false,
      booking_url:''
    };
  },
  computed:{
    dataUser() {
      return JSON.parse(localStorage.getItem('tenant'));
    },
  },
  mounted() {
    let data = this.$route.query;
    if(this.dataUser == null){
      AuthService.getDataTenant().then(
        (response) => {
          var data = response.data;
          this.user.tenant_id = data.id;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }else{
      this.user.tenant_id = this.dataUser.id;
    }

    if(data.booking_url != null)
    {
      this.booking_url = data.booking_url;
    }
    // let data = this.$route.query;
    // let email = ''

    // if(data.email != null){
    //   email = this.$CryptoJS.AES.decrypt(
    //     data.email,
    //     "123#$%"
    //   ).toString(this.$CryptoJS.enc.Utf8);

    //   this.user.email = email
    //   this.is_verify_view = true
    // }

  
  },
  methods: {
    userRegister() {
      this.loading = true;
      this.user.code = "CUS-U"+Math.ceil(Math.random()*1000000)
      this.$store.dispatch("auth/register", this.user).then(
        () => {
          Swal.fire({
            text: "Berhasil Registrasi",
            icon: "success",
            customClass: {
              confirmButton: "btn fw-bold btn-danger",
            },
          }).then((result) =>{
            if (result.isConfirmed) {
              if(this.booking_url != ''){
                  this.$router.push({
                    path: '/login',
                    query: {
                      email: this.$CryptoJS.AES.encrypt(
                        this.user.email.toString(),
                        "123#$%"
                      ).toString(),
                      booking_url:  this.booking_url
                    },
                  });
                }else{
                  this.$router.push({
                  path: '/login',
                  query: {
                    email: this.$CryptoJS.AES.encrypt(
                      this.user.email.toString(),
                      "123#$%"
                    ).toString(),
                  },
                });
                }
              }
          });
          
        },
        (error) => {
          this.loading = false;
          Swal.fire({
            text: error.response.data.message,
            icon: "warning",
            customClass: {
              confirmButton: "btn fw-bold btn-danger",
            },
          });
        }
      );
    },
    showPassword: function () {
      if (this.type == "password") {
        this.type = "text";
        this.showOrHide = "Hide";
        this.icon = "fa fa-eye";
      } else {
        this.type = "password";
        this.showOrHide = "Show";
        this.icon = "fa fa-eye-slash";
      }
    },
    backToLogin(){
      this.is_verify_view = false;
      this.$router.push(this.$route.path)
    }
  },
};
</script>