import { createStore } from "vuex";
import { auth } from "./auth.module";
import { mycart } from "./mycart.module";

const store = createStore({
    state:{
        layout: 'main-layout'
    },
    modules: {
        auth,
        mycart,
    },
});

export default store;