<template>
  <div>
    <section class="pt-20 w-full">
      <div class="flex-col xl:flex-row flex justify-around items-center p-20">
        <div>
          <img src="../assets/images/animation.gif" alt="" class="w-[500px] h-[500px]">
        </div>

        <div>
          <div style="width: 100%; height: 100%; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 64px; display: inline-flex">
            <div style="color: #424242; font-size: 48px; font-family: Plus Jakarta Sans; font-weight: 600; line-height: 57.60px; word-wrap: break-word">We Produce</div>
            <div style="align-self: stretch; height: 144px; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: flex">
                <div style="align-self: stretch; justify-content: space-between; align-items: center; display: inline-flex">
                    <div style="flex: 1 1 0; color: #424242; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Podcast</div>
                </div>
                <div style="align-self: stretch; justify-content: space-between; align-items: center; display: inline-flex">
                    <div style="flex: 1 1 0; color: #424242; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Tiktok Live</div>
                </div>
                <div style="align-self: stretch; justify-content: space-between; align-items: center; display: inline-flex">
                    <div style="flex: 1 1 0; color: #424242; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Anchor News</div>
                </div>
                <div style="align-self: stretch; justify-content: space-between; align-items: center; display: inline-flex">
                    <div style="flex: 1 1 0; color: #424242; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Mini Konser</div>
                </div>
            </div>
        </div>
        </div>
      </div>
    </section>

    <section class="p-20 mx-auto w-ful" style="background: #231F20;">
      <div
        class="text-left text-3xl font-semibold leading-9 text-white font-primary"
      >
      Package
      </div>
      <div class="mt-10" v-for="item in products" :key="item.id">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div>
              <div v-if="item.picture_path != null">
                <img class="w-[400px] h-[300px]" :src="url + item.picture_path" />
              </div>
              <div v-else>
                <img
                  class="w-[400px] h-[300px]"
                  src="../assets/images/hero-content.png"
                  alt="room"
                />
              </div>
            </div>
            <div>
              <div class="font-bold text-2xl text-white truncate text-left">
                {{ item.name }}
              </div>
              <div class="font-normal text-base text-white truncate text-left mt-2">
                Rp {{ item.price ? formatPrice(item.price) : 0 }} / {{item.term}}
              </div>
              <div class="mt-2 font-normal text-sm text-white">
                Jadikan visi kamu menjadi kenyataan  dalam studio yang canggih dan mutakhir. 
              </div>
              <div class="mt-10" >
                <button
                class="text-base font-normal" style="color: #EB282E"
                @click="pushRoute(item.id, item.has_room)"
              >
                Book Now  <i class="fa fa-solid fa-arrow-right"></i>
              </button>
              <hr style="color: #EB282E;width:20%;height: 2px;" class="mt-2">
              </div>
              <div class="mt-10">
                <hr class="h-1 my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700" style="width:50%">
              </div>
            </div>
        </div>
      </div>
    </section>

    <section class="p-20 mx-auto w-ful bg-white">
      <div
      class="text-left text-2xl lg:text-3xl font-semibold leading-9 text-black font-primary"
      >
      Apa yang anda dapatkan ?
      </div>
      <div class="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <div style="width: 100%; height: 100%; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: inline-flex">
                <div style="color: #424242; font-size: 24px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 28.80px; word-wrap: break-word">Layanan</div>
                <div style="padding-top: 8px; padding-bottom: 8px; border-bottom: 1px #9E9E9E solid; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                    <div style="color: #9E9E9E; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Peralatan terbaik</div>
                </div>
                <div style="padding-top: 8px; padding-bottom: 8px; border-bottom: 1px #9E9E9E solid; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                    <div style="color: #9E9E9E; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Operator Berpengalaman</div>
                </div>
                <div style="padding-top: 8px; padding-bottom: 8px; border-bottom: 1px #9E9E9E solid; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                    <div style="color: #9E9E9E; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Talenta Terlatih</div>
                </div>
                <div style="padding-top: 8px; padding-bottom: 8px; border-bottom: 1px #9E9E9E solid; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                    <div style="color: #9E9E9E; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Layanan Catering (Optional)</div>
                </div>
            </div>
          </div>
          <div>
            <div style="width: 100%; height: 100%; flex-direction: column; justify-content: flex-start; align-items: flex-start; gap: 16px; display: inline-flex">
              <div style="color: #424242; font-size: 24px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 28.80px; word-wrap: break-word">Fasilitas Alat</div>
              <div style="padding-top: 8px; padding-bottom: 8px; border-bottom: 1px #9E9E9E solid; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                  <div style="color: #9E9E9E; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Atem Mini Pro Extreme</div>
              </div>
              <div style="padding-top: 8px; padding-bottom: 8px; border-bottom: 1px #9E9E9E solid; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                  <div style="color: #9E9E9E; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Sony NXR5R Camcorder</div>
              </div>
              <div style="padding-top: 8px; padding-bottom: 8px; border-bottom: 1px #9E9E9E solid; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                  <div style="color: #9E9E9E; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">RodeCaster Pro</div>
              </div>
              <div style="padding-top: 8px; padding-bottom: 8px; border-bottom: 1px #9E9E9E solid; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                  <div style="color: #9E9E9E; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Aputure light Stroom 300D</div>
              </div>
              <div style="padding-top: 8px; padding-bottom: 8px; border-bottom: 1px #9E9E9E solid; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                  <div style="color: #9E9E9E; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Behringer X32 Digital</div>
              </div>
              <div style="padding-top: 8px; padding-bottom: 8px; border-bottom: 1px #9E9E9E solid; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                  <div style="color: #9E9E9E; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">Wireless MIC Sennheiser EW 135</div>
              </div>
              <div style="padding-top: 8px; padding-bottom: 8px; border-bottom: 1px #9E9E9E solid; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                  <div style="color: #9E9E9E; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">43 inch TV Preview</div>
              </div>
              <div style="padding-top: 8px; padding-bottom: 8px; border-bottom: 1px #9E9E9E solid; justify-content: flex-start; align-items: center; gap: 16px; display: inline-flex">
                  <div style="color: #9E9E9E; font-size: 20px; font-family: Plus Jakarta Sans; font-weight: 500; line-height: 24px; word-wrap: break-word">2 Computer High Spect</div>
              </div>
          </div>
          </div>
      </div>
    </section>

    <section class="p-20 mx-auto w-ful" style="background: #231F20;">
      <div
      class="text-center text-2xl lg:text-3xl font-semibold leading-9 text-white font-primary"
      >
      Gallery
      </div>
      <div class="mt-10 flex items-center justify-center">
        <video class="w-100"  autoplay muted controls>
          <source src="../assets/video/hero_video_home.mp4" type="video/mp4">
        </video>
      </div>
    </section>

    <section class="whats_on mx-auto w-full p-20">
      <div class="flex-col xl:flex-row flex justify-between items-center">
        <p class="text-black tracking-tight font-display text-2xl font-bold">
          Whats On
        </p>
        <a href="/whats-on"
          class="font-second-brand tracking-tight font-display text-1xl font-bold"
        >
          Lihat Semua
        </a>
      </div>

      <div
        class="mt-10 grid gap-4 xl:grid-cols-4 lg:xl:grid-cols-4 md:xl:grid-cols-3 sm:xl:grid-cols-1" 
       
      >
        <div  v-for="item in whatsOn" 
        :key="item.id">
        <div class="bg-white shadow-md rounded bg-white">
          <div>
            <img
            :src="url+item.picture_path"
            class="rounded-t w-full"
          />
           
          </div>
          <div class="p-2">
            <article class="text-gray-700 mt-2">{{item.name}}</article>
            <div class="mt-2 text-sm font-paragraph"  v-html="item.content ? item.content.slice(0, 250 - 3) + '...' : ''">
            
            </div>
          </div>
          <div class="px-2 py-4">
            <a  class="inline-flex items-center font-second-brand cursor-pointer" @click="goToDetail(item.id)">
              Baca selengkapnya
            </a>
          </div>
        </div>
        </div>
       
      </div>
    </section>

    <section class="faq mx-auto w-full p-20">
      <div class="dark:bg-gray-800 dark:text-gray-100">
        <div class="container flex flex-col justify-center p-4 mx-auto md:p-8">
          <h2 class="mb-12 text-4xl font-bold leadi text-center sm:text-3xl">
            Frequently Asked Questions
          </h2>
          <div
            class="flex flex-col divide-y sm:px-8 lg:px-12 xl:px-32 divide-gray-700"
          >
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Apa itu Creativehub ?
              </summary>
              <div class="px-4 pb-4">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Est
                  aspernatur quae, eos explicabo odit minima libero veniam
                  similique quibusdam doloribus facilis ipsa accusantium vel
                  maiores corrupti! Libero voluptate a doloribus?
                </p>
              </div>
            </details>
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Apa saja fasilitas yang ditawarkan oleh Creativehub ?
              </summary>
              <div class="px-4 pb-4">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Est
                  aspernatur quae, eos explicabo odit minima libero veniam
                  similique quibusdam doloribus facilis ipsa accusantium vel
                  maiores corrupti! Libero voluptate a doloribus?
                </p>
              </div>
            </details>
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Apakah Xircle hanya bisa disewa dalam jangka waktu panjang?
              </summary>
              <div class="px-4 pb-4 space-y-2">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
                  voluptates aspernatur dolores in consequatur doloremque
                  inventore reprehenderit, consequuntur perspiciatis architecto.
                </p>
                <p>
                  Sed consectetur quod tenetur! Voluptatibus culpa incidunt
                  veritatis velit quasi cupiditate unde eaque! Iure,
                  voluptatibus autem eaque unde possimus quae.
                </p>
              </div>
            </details>
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Apakah saya harus memiliki akun sebelum melakukan pemesanan ?
              </summary>
              <div class="px-4 pb-4 space-y-2">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
                  voluptates aspernatur dolores in consequatur doloremque
                  inventore reprehenderit, consequuntur perspiciatis architecto.
                </p>
                <p>
                  Sed consectetur quod tenetur! Voluptatibus culpa incidunt
                  veritatis velit quasi cupiditate unde eaque! Iure,
                  voluptatibus autem eaque unde possimus quae.
                </p>
              </div>
            </details>
            <details>
              <summary class="py-2 outline-none cursor-pointer focus:underline">
                Bagaimana cara pemesanan Creativehub ?
              </summary>
              <div class="px-4 pb-4 space-y-2">
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
                  voluptates aspernatur dolores in consequatur doloremque
                  inventore reprehenderit, consequuntur perspiciatis architecto.
                </p>
                <p>
                  Sed consectetur quod tenetur! Voluptatibus culpa incidunt
                  veritatis velit quasi cupiditate unde eaque! Iure,
                  voluptatibus autem eaque unde possimus quae.
                </p>
              </div>
            </details>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import AuthService from "@/services/auth.service";
import ApiService from "@/services/api.service";
import productService from "@/services/product.service";
import { webUrl } from "../utils/config";
import { CCarousel, CCarouselItem } from "@coreui/vue";
export default {
  name: "Home",
  components: { CCarousel, CCarouselItem },
  data() {
    return {
      url: webUrl,
      tenant: "",
      image_src: "../assets/image/banner/banner.jpg",
      isTab: "",
      isTabGallery: "",
      categories: [],
      locations: [],
      products: [],
      whatsOn:[],
      locationId: "",
      categoryId: "",
      secret: "123#$%",
      page: 1,
      totalPages: 10,
      total: 10,
      perPage: 8,
      currentPage: 1,
      hasMorePages: true,
    };
  },
  computed: {
    indexStart() {
      return (this.currentPage - 1) * this.perPage;
    },
    indexEnd() {
      return this.indexStart + this.perPage;
    },
    paginated() {
      return this.productPrices.slice(this.indexStart, this.indexEnd);
    },
  },
  mounted() {
    AuthService.getDataTenant().then(
      (response) => {
        var data = response.data;
        this.tenant = data;
        this.getLocation(this.tenant.id);
        this.getWhatOn(this.tenant.id);
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },
    getLocation(tenantId) {
      ApiService.getDataLocation(tenantId).then(
        (response) => {
          var data = response.data;
          this.locations = data;
          this.locationId = data[0].id;
          this.getCategories(this.tenant.id);
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getCategories(tenantId) {
      productService.getDataProductCategories(tenantId).then(
        (response) => {
          var data = response.data.data;
          this.categories = data;
          this.categoryId = data[0].id;
          this.getProduct();
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getProduct() {
      productService
        .getDataProducts(
          this.tenant.id,
          this.categoryId,
          this.locationId,
          this.page
        )
        .then(
          (response) => {
            var data = response.data.data;
            console.log(data.data);
            this.products = data.data;

            this.currentPage = data.current_page;
            this.total = data.total;
            this.totalPages = data.last_page;
            this.last_page = data.last_page;
            this.isLoading = false;
            this.isProductPrices = false;
            // if(data.data.length > 1)
            // {
            //   this.products = data.data;

            //   this.currentPage = data.current_page;
            //   this.total = data.total;
            //   this.totalPages = data.last_page;
            //   this.last_page = data.last_page;
            //   this.isLoading = false;
            //   this.isProductPrices = false;
            // }else{
            //   this.getRoom(this.tenant_id,data.data[0].id);
            // }
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    },
    getWhatOn(tenantId) {
      productService.getWebContentWithPaginate(tenantId, "whats_on",this.page,this.totalPage).then(
        (response) => {
          var data = response.data.data;
          this.whatsOn = data.data;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    handleTab(val) {
      this.isTab = val;
    },
    handleTabGallery(val) {
      this.isTabGallery = val;
    },
    pushRoute(Id, has_room) {
      if (Id != null) {
        this.$router
          .push({
            name: "detail-product",
            query: {
              location_id: this.locationId,
              product_id: Id,
            },
          })
          .then((response) => {
            location.reload();
          });
      }
    },
    goToDetail(id){
            this.$router
            .push({
                name: "whats-on-detail",
                query: {
                    Id: id,
                }
            })
            .then((response) => {
                location.reload();
            });
        }
  },
};
</script>
