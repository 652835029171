import productService from "@/services/product.service";

const cart = JSON.parse(localStorage.getItem('cart'));
const initialState = cart

export const mycart = {
    namespaced: true,
    state: initialState,
    actions: {
        addToCart({ commit }, cart) {
            return productService.addToCart(cart).then(
                cart => {
                    commit('addToCartSuccess', cart);
                    return Promise.resolve(cart);
                },
                error => {
                    commit('addToCartFailure');
                    return Promise.reject(error);
                }
            );
        },
        removeFromCart({ commit }, cart) {
            return productService.removeFromCart(cart).then(
                cart => {
                    commit('removeFromCartSuccess', cart);
                    return Promise.resolve(cart);
                },
                error => {
                    commit('removeFromCartFailure');
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        addToCartSuccess(state, cart) {
            state.cart = cart;
        },
        addToCartFailure(state) {
            state.cart = null;
        },
        removeFromCartSuccess(state, cart) {
            state.cart = cart;
        },
        removeFromCartFailure(state) {
            state.cart = null;
        },
    }
};