<template>
    <div>
      <div class="mx-auto md:px-36 px-10 w-full py-32">
        <div class="flex flex-row flex-wrap py-4">
          <aside class="w-full sm:w-1/3 md:w-1/4  px-2">
            <div class="sticky top-0 p-2 w-full">
              <div class="antialiased bg-gray-200 min-h-full p-8">
                <div class="flex justify-center">
                  <nav id="nav" class="w-56 relative">
                    <span class="absolute h-10 w-full bg-white rounded-lg shadow ease-out transition-transform transition-medium" :style="{ transform: `translateY(calc(100% * ${selected}))` }"></span>
                    <ul class="relative">
                      <li>
                        <button type="button" @click="select(0,'profile')" :aria-selected="selected === 0" class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline">
                          <svg :class="selected === 0 ? 'text-yellow-600' : 'text-gray-500'" class="h-6 w-6 transition-all ease-out transition-medium" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                          </svg>
                          <span :class="selected === 0 ? 'text-yellow-600' : 'text-gray-700'" class="ml-2 text-sm font-medium transition-all ease-out transition-medium">
                            Profile
                          </span>
                        </button>
                      </li>
                      <li>
                        <button type="button" @click="select(1,'password')" :aria-selected="selected === 1" class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline">
                          <svg :class="selected === 1 ? 'text-yellow-600' : 'text-gray-500'" class="h-6 w-6 transition-all ease-out transition-medium" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                          </svg>
                          <span :class="selected === 1 ? 'text-yellow-600' : 'text-gray-700'" class="ml-2 text-sm font-medium transition-all ease-out transition-medium">
                            Change Password
                          </span>
                        </button>
                      </li>
                      <li>
                        <button type="button" @click="select(2,'history')" :aria-selected="selected === 2" class="py-2 px-3 w-full flex items-center focus:outline-none focus-visible:underline">
                          <svg :class="selected === 2? 'text-yellow-600' : 'text-gray-500'" class="h-6 w-6 transition-all ease-out transition-medium" viewBox="0 0 24 24" fill="currentColor">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13 11.586l2.536 2.535a1 1 0 11-1.415 1.415l-2.828-2.829A.997.997 0 0111 12V8a1 1 0 112 0v3.586z"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 1a1 1 0 011 1v1.998c3.918-2.945 9.506-2.635 13.071.93 3.905 3.906 3.905 10.238 0 14.143-3.905 3.905-10.237 3.905-14.142 0A9.972 9.972 0 012 12a1 1 0 112 0 8 8 0 101.777-5.029A1 1 0 014 6.341V2a1 1 0 011-1z"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.293 12.707A.997.997 0 0111 12V8a1 1 0 112 0v3.586l2.536 2.535a1 1 0 11-1.415 1.415l-2.828-2.829zM5.934 1.643A1 1 0 004 2v4.342a1 1 0 001.777.63A8 8 0 114 12v-.001a1 1 0 10-2 0c0 2.558.977 5.119 2.929 7.071 3.905 3.905 10.237 3.905 14.142 0 3.844-3.844 3.904-10.04.18-13.957A10.004 10.004 0 006 3.999V2a.998.998 0 00-.066-.357zM5 2.25z"/>
                          </svg>
                          <span :class="selected === 2 ? 'text-yellow-600' : 'text-gray-700'" class="ml-2 text-sm font-medium transition-all ease-out transition-medium">
                            My Transaction
                          </span>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </aside>
          
          <main role="main" class="w-full sm:w-2/3 md:w-3/4 pt-1" v-bind:class="selectedTab == 'profile' ? '' :'hidden'">
            <div class="bg-gray-200 rounded-lg  sm:p-6 md:p-8">
              <form  action="#" @submit.prevent="updateProfile" class="mx-auto p-5">
                  <h5 class="text-2xl font-bold text-gray">Akun</h5>

                  <div class="mt-5">
                    <label class="font-normal text-gray">Profil</label>
                    <p class="text-xs font-medium text-gray">Informasi ini akan ditampilkan untuk umum jadi berhati-hatilah dengan apa yang anda bagikan.</p>
                  </div>

                  <div class="mt-5">
                    <label class="block text-gray text-md font-bold">Foto</label>
                    <div class="m-auto flex  items-center">
                      <img class="ml-2 w-10 h-10 rounded-full border-2 border-gray-600 mr-3" :src="avatar" alt="Saleh Mir" />
                      <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="previewImage"/>
                      <span class="bg-white hover:bg-700 text-black font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2 cursor-pointer"  @click="onPickFile">
                        Ubah
                      </span>

                      <span class="bg-gray-700 hover:bg-red-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2 cursor-pointer" @click="removeImage">
                        Remove
                      </span>
                    </div>
                  </div>

                  <div class="mt-10">
                    <div class="grid grid-cols-2 gap-4">
                      <div class="flex grid">
                        <label class="block text-gray text-md font-bold">Nama</label>
                        <input type="text" name="name" placeholder="Input name" v-model="user.name" class="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required/>
                      </div>

                      <div class="flex grid">
                        <label class="block text-gray text-md font-bold">Email</label>
                        <input type="email" name="email" placeholder="Input email" v-model="user.email" class="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required/>
                      </div>
                    </div>
                    <div class="flex grid mt-2">
                      <label class="block text-gray text-md font-bold">Telepon</label>
                      <input type="number" name="name" placeholder="Input phone" v-model="user.phone" class="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required/>
                    </div>
                  </div>
                  
                  <div class="mt-10">
                    <div class="m-auto flex justify-between items-center">
                      <button class="bg-gray-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2">
                        Cancel
                      </button>
                      <button class="bg-white hover:bg-700 text-black font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2">
                        Save
                      </button>
                    </div>
                  </div>
              </form>
            </div>
          </main>

          <main role="main" class="w-full sm:w-2/3 md:w-3/4 pt-1" v-bind:class="selectedTab == 'password' ? '' :'hidden'">
            <div class="bg-gray-200 rounded-lg  sm:p-6 md:p-8">
              <form action="#" @submit.prevent="updatePassword" class="mx-auto p-5">
                <h5 class="text-2xl font-bold text-gray">Change Password</h5>
                <div class="mt-10">
                  <div class="grid grid-cols-1 mb-5">
                    <div class="flex grid">
                      <label class="block text-gray">Old Password</label>
                      <div class="flex flex-wrap items-stretch w-full relative h-15 items-center">
                        <input :type="type" name="password" v-model="user.password"   placeholder="Enter Password" minLength=8 class="flex-shrink flex-grow flex-auto leading-normal w-px  rounded px-4 py-3  bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required />
                        <span @click="showPassword()" class="absolute inset-y-0 right-0 flex items-center pr-3">
                          <i  :class="icon"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="grid gap-4 md:grid-cols-1 lg:grid-cols-2">
                    <div class="flex grid">
                      <label class="block text-gray">New Password</label>
                      <div class="flex flex-wrap items-stretch w-full relative h-15 items-center">
                      <input :type="type" name="password" v-model="user.new_password"   placeholder="Enter Password" minLength=8 class="flex-shrink flex-grow flex-auto leading-normal w-px  rounded px-4 py-3  bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required />           
                        <span @click="showPassword()" class="absolute inset-y-0 right-0 flex items-center pr-3">
                          <i :class="icon"></i>
                        </span>
                      </div>
                    </div>

                    <div class="flex grid">
                      <label class="block text-gray">Password Confirmation</label>
                      <div class="flex flex-wrap items-stretch w-full relative h-15 items-center">
                        <input :type="type" name="password" v-model="user.new_password_confirmation"   placeholder="Enter Password" minLength=8 class="flex-shrink flex-grow flex-auto leading-normal w-px  rounded px-4 py-3  bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none shadow-inner text-black" required />
                        <span @click="showPassword()" class="absolute inset-y-0 right-0 flex items-center pr-3">
                          <i :class="icon"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="mt-10">
                  <div class="m-auto flex justify-between items-center">
                    <button class="bg-gray-700 hover:bg-700 text-white font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2">
                      Cancel
                    </button>
                    <button class="bg-white hover:bg-700 text-black font-normal py-2 px-4 rounded-full text-sm mr-1 mb-2">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </main>

          <main role="main" class="w-full sm:w-2/3 md:w-3/4 pt-1" v-bind:class="selectedTab == 'history' ? '' :'hidden'">
            <div class="bg-gray-200 rounded-lg  sm:p-6 md:p-8  p-5">
              <h5 class="text-2xl font-bold text-gray">My Transaction</h5>
              <div v-if="isLoading" class="flex justify-center items-center">
                <div class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status">
                  <span class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
              </div>
              <div class="overflow-y-auto h-3/4" v-else>
                <div v-for="detail in sales_orders" :key="detail.id" >
                  <div class="w-full rounded overflow-hidden shadow-lg bg-white mt-5 mb-5">
                    <div class="grid grid-cols-2 gap-4">
                      <div class="px-2 py-4">
                        <img class="rounded-lg" src="../assets/images/hero-banner.png" alt="" >
                      </div>
                      <div class="px-2 py-4">
                        <div class="font-bold text-base text-black truncate">{{detail.code}} </div>
                        <div class="font-bold text-base text-black truncate">{{detail.sales_order_details[0].name}}</div>
                        <p class="text-gray-700 text-sm">
                          Status pembayaran : 
                          <span v-if="detail.payment_status == 'not paid'">
                            <span class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">{{detail.payment_status}}</span>
                          </span>
                          <span v-else-if="detail.payment_status == 'paid'">
                            <span class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{{detail.payment_status}}</span>
                          </span>
                          <span v-else-if="detail.payment_status == 'on invoice'">
                            <span class="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">{{detail.payment_status}}</span>
                          </span>
                        </p>
                        <p class="text-gray-700 text-sm">
                          Nama pemesan: {{detail.customer.person.name}}
                        </p>
                        <p class="text-gray-700 text-sm">
                          Mulai tanggal:  {{ date(detail.started_at) }}
                        </p>
                        <p class="text-gray-700 text-sm">
                          Sampai tanggal: {{ date(detail.ended_at) }}
                        </p>
                        <div class="mt-2">
                          <div class="font-bold text-base text-black truncate">Total Price: </div>
                          <p class="text-gray-700 text-sm">
                            {{formatPrice((detail.total_price - detail.total_discount + detail.total_service_charge + detail.total_tax))}}
                          </p>
                        </div>
                        <div class="mt-5">
                          <button v-if="detail.payment_status == 'not paid' && detail.xendit_invoice_url != null" class="px-4 py-2 bg-yellow-500 hover:bg-yellow-600 text-white text-sm font-medium rounded-full" data-modal-target="large-modal" data-modal-toggle="large-modal" @click="payProduct(detail.xendit_invoice_url)">
                            Lakukan pembayaran
                          </button>

                          <button class="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium rounded-full ml-2 mt-2" data-modal-target="large-modal" data-modal-toggle="large-modal" @click="modalDetail(detail.id)">
                            Detail Booking
                          </button>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </div>
              
            </div>
          </main>
        </div>
      </div>
      <div id="large-modal" tabindex="-1" 
          v-bind:class="isModal == false ? 'hidden' : 'flex justify-center items-center fixed top-0 left-0 right-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full '">
            <div class="relative w-full max-w-4xl max-h-full">
                <!-- Modal content -->
                <div class="relative bg-gray-100 rounded-lg shadow">
                    <!-- Modal header -->
                    <div class="flex items-center justify-between p-3 border-b rounded-t dark:border-gray-600">
                        <h3 class="text-xl font-medium text-gray-900 text-black">
                            Detail Booking
                        </h3>
                        <button type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="large-modal" @click="isModal = false">
                            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>
                    <!-- Modal body -->
                    <div class="p-6 space-y-6">
                      <div v-if="isModalLoading" class="flex justify-center items-center">
                        <div
                          class="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                          role="status">
                          <span
                            class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                            >Loading...</span
                          >
                        </div>
                      </div>
                      <div v-else>
                        <table class="w-full text-sm text-left text-black">
                          <tr class="text-white">
                            <td width="30%" class="text-left text-black">Booking Code</td>
                            <td width="70%" class="text-left text-black">
                              {{ sales_order.code }}
                            </td>
                          </tr>
                          <tr class="text-white">
                            <td width="30%" class="text-left text-black">Customer</td>
                            <td width="70%" class="text-left text-black">
                              {{ sales_order.customer.person.name }}
                            </td>
                          </tr>
                          <tr
                          v-for="item in sales_order.sales_order_details"
                          v-bind:key="item.id"
                          class="text-white"
                        >
                          <td width="30%" class="text-left text-black">Service</td>
                          <td width="70%" class="text-left text-black">
                            {{ item.name }}
                          </td>
                          </tr>
                          <tr class="text-white">
                            <td width="30%" class="text-left text-black">Date</td>
                            <td width="70%" class="text-left text-black">
                              {{ date(sales_order.started_at) }} -
                              {{ date(sales_order.ended_at) }}
                            </td>
                          </tr>
                          <tr class="text-white">
                            <td width="30%" class="text-left text-black">Length Of Term</td>
                            <td width="70%" class="text-left text-black">
                              {{ sales_order.length_of_term }}
                            </td>
                          </tr>
                          <tr class="text-white">
                            <td width="30%" class="text-left text-black">Total Price</td>
                            <td width="70%" class="text-left text-black">
                              Rp.{{formatPrice((sales_order.total_price - sales_order.total_discount + sales_order.total_service_charge + sales_order.total_tax))}}
                            </td>
                          </tr>
                        </table>
                      </div>
                      
                    </div>
                    <!-- Modal footer -->
                    <div class="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                        <!-- <button data-modal-hide="large-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button> -->
                        <button data-modal-hide="large-modal" type="button" class="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" @click="isModal = false;">Close</button>
                    </div>
                </div>
            </div>
      </div>
    </div>
</template>
<style scoped>
.ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .transition-fastest {
    transition-duration: 75ms;
  }
  .transition-faster {
    transition-duration: 100ms;
  }
  .transition-fast {
    transition-duration: 150ms;
  }
  .transition-medium {
    transition-duration: 200ms;
  }
  .transition-slow {
    transition-duration: 300ms;
  }
  .transition-slower {
    transition-duration: 500ms;
  }
  .transition-slowest {
    transition-duration: 700ms;
  }
  
  .transition-all {
    transition-property: all;
  }
  .transition-opacity {
    transition-property: opacity;
  }
  .transition-transform {
    transition-property: transform;
  }
  
  .focus-visible.focus-visible\:underline {
    text-decoration: underline;
  }
  
</style>
<script>
import Swal from "sweetalert2";
import AuthService from "../services/auth.service";
import { webUrl } from '../utils/config'
import productService from "@/services/product.service";
import moment from "moment";
export default {
  name: "nav-bar",
  data() {
    return {
        isModal:false,
        isLoading: true,
        isModalLoading:true,
        url:webUrl,
        selected: 0,
        selectedTab: 'profile',
        avatar: 'https://raw.githubusercontent.com/vuetailwind/storage/master/avatars/saleh-avatar.jpg',
        user:{
            tenant_id:'',
            customer_id:'',
            code:'',
            name:'',
            email:'',
            phone:'',
            city: "-",
            country:"-",
            identity_type:"id_cards",
            identity_number: "-",
            address:"-",
            image:null,
            password:'',
            new_password:'',
            new_password_confirmation: ''
        },
        type: "password",
        showOrHide: "Show",
        icon: "fa fa-eye-slash",
        message: "",
        sales_orders:[],
        sales_order:{}

    }
  },
  computed: {
    dataUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.user.name = this.dataUser.user.name;
    this.user.email = this.dataUser.user.email;
    this.user.code = this.dataUser.user.person.customer.code;
    this.user.customer_id = this.dataUser.user.person.customer.id;
    this.user.tenant_id = this.dataUser.user.person.tenant_id;
    this.avatar = this.url+this.dataUser.user.avatar;
    this.user.image = this.url+this.dataUser.user.avatar;
    this.user.phone = this.dataUser.user.person.phone;
    this.getSalesOrder(this.user.customer_id);
    
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    date: function (date) {
      return moment(date).format("MMMM Do YYYY HH:mm");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getSalesOrder(customerID){
      productService.getDataBooking(customerID).then(
        (response) => {
          var data = response.data.data;
          this.sales_orders = data;
          this.isLoading = false;
        
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    select(val,content){
      this.selected = val;
      this.selectedTab = content;
    },
    onPickFile () {
    this.$refs.fileInput.click()
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.avatar = e.target.result;
          this.user.image= e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
      }
    },
    removeImage (){
        this.avatar = 'https://raw.githubusercontent.com/vuetailwind/storage/master/avatars/saleh-avatar.jpg';
        this.user.image = null;
    },
    showPassword: function () {
      if (this.type == "password") {
        this.type = "text";
        this.showOrHide = "Hide";
        this.icon = "fa fa-eye";
      } else {
        this.type = "password";
        this.showOrHide = "Show";
        this.icon = "fa fa-eye-slash";
      }
    },
    updateProfile(){
      AuthService.updatedProfile(this.user).then(
          (response) => {
            this.getDataUser();
            Swal.fire({
            title: 'Success, Update your profile.',
            icon: "success",
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
                confirmButton: "bg-black hover:bg-black text-white font-bold py-2 px-4 rounded",
            },
            }).then((result) => {
              location.reload();
            })
            
          },
          (error) => {
            this.loading = false;
            Swal.fire({
              text: error.response.data.message,
              icon: "warning",
              customClass: {
                confirmButton: "btn fw-bold btn-danger",
              },
            });
          }
        );
    },
    getDataUser(){
      this.$store.dispatch("auth/me").then( response => {
      }, error => {
        Swal.fire({
          text: "Email atau password anda salah",
          icon: "warning",
          customClass: {
            confirmButton: "btn fw-bold btn-danger",
          },
        }).then((result) => {
          this.isLogin = false;
         
          });
      
      });
    },
    updatePassword(){
      AuthService.updatedPassword(this.user).then(
          (response) => {
            console.log(response.message);
            Swal.fire({
            title: 'Success, Update your profile.',
            icon: "success",
            showCancelButton: false,
            confirmButtonText: 'OK',
            customClass: {
                confirmButton: "bg-black hover:bg-black text-white font-bold py-2 px-4 rounded",
            },
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
                // if (result.isConfirmed) {
                //     Swal.fire('Saved!', '', 'success')
                // }
            })
            
          },
          (error) => {
            this.loading = false;
            Swal.fire({
              text: error.response.data.message,
              icon: "warning",
              customClass: {
                confirmButton: "btn fw-bold btn-danger",
              },
            });
          }
        );
    },
    modalDetail(Id){
      this.isModal = true;
      productService.getDataBookingDetail(Id).then(
        (response) => {
          var data = response.data.data;
          this.sales_order = data;
          this.isModalLoading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
      
    },
    payProduct(url){
      window.open(url, '_blank');
    }
  },
};
</script>