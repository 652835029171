<template>
  <div class="app">
    <div class="h-screen">
      <div class="fixed left-0 top-0 w-full z-10 ease-in duration-300 bg-black">
        <div class="max-w-[1240px] m-auto flex justify-between items-center p-2 text-white font-normal">
            <a href="/">
              <img src="../assets/images/logo_white.png" class="h-10 mr-3" alt="Creative-Hub Logo" />
                </a>
              <ul class="hidden sm:flex items-center">
                <li class="p-2">
                  <a class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-red-600 hover:text-white"   href="/location" >Location</a>
                
                </li>
                <li class="p-2">
                  <a class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-red-600 hover:text-white"   href="/whats-on" >Article</a>
                 
                </li>
                <li class="p-2">
                  <a class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-red-600 hover:text-white"   href="https://telpro.my.id/affiliate/" target="_blank">Affiliate</a>
                 
                </li>
               
                  <li v-if="currentUser">
                    <vdropdown placement="right">
                      <!-- Button content -->
                      <template v-slot:button>
                        <div v-if="currentUser.user.avatar != null">
                          <img class="ml-2 w-10 h-10 rounded-full border-2 border-gray-600 mr-3" :src="url+currentUser.user.avatar" />
                        </div>
                        <div v-else>
                        <img class="ml-2 w-10 h-10 rounded-full border-2 border-gray-600 mr-3" src="https://raw.githubusercontent.com/vuetailwind/storage/master/avatars/saleh-avatar.jpg" alt="Saleh Mir" />
                        </div>
      
                        <span class="mr-2">{{currentUser.user.name}}</span>
                  
                        <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                          <path d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z" />
                        </svg>
                      </template>
                  
                      <!-- Opened dropdown content -->
                      <template v-slot:content>
                        <router-link :to="{ path: '/profile' }">
                          <a class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-indigo-600 hover:text-white" href="#">Settings</a>
                        </router-link>
                  
                        <hr />
                  
                        <a class="flex w-full justify-between items-center rounded px-2 py-1 my-1 hover:bg-red-600 hover:text-white" href="#" @click="logOut()">Logout</a>
                      </template>
                    </vdropdown>
                  </li>
                  <li v-else class='p-4'>
                    <router-link :to="{ path: '/login' }">
                      <a >Login</a>
                    </router-link>
                  </li>
                 
              </ul>
           
            <div @click="hadleNav" class="block sm:hidden z-10">
              <i class="fa fa-times" style="font-size: 30px" v-if="isToggle"></i>
              <i class="fa fa-bars" style="font-size: 30px" v-else></i>
             
            </div>
            <div v-bind:class="isToggle ? 
            'sm:hidden absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center w-full h-screen bg-white text-center ease-in duration-300 overflow-y-auto' :
            'sm:hidden absolute top-0 left-[-100%] right-0 bottom-0 flex justify-center items-center w-full h-screen bg-white text-center ease-in duration-300 overflow-y-auto'">
                <div class="flex flex-col justify-center items-center mt-28 mb-20">
                  <a href="/" class="text-2xl font-bold  hover:text-black my-4">Home</a>
                  
                  <span v-if="currentUser" class="my-4">
                    <a href="/profile" class="text-2xl font-bold  hover:text-black my-4">Settings</a>
                  
                  </span>
                  <span v-if="currentUser" class="my-4">
                    <a  class="text-2xl font-bold  hover:text-black" @click="logOut()">Logout</a>
                  </span>
                  <span v-else>
                    <a href="/login" class="text-2xl font-bold  hover:text-black my-4">Login</a>
                  </span>
                 
                
                </div>
            </div>
        </div> 
      </div>
      <router-view />
      <input class='chatMenu hidden' id='offchatMenu' type='checkbox' />
      <label class='chatButton' for='offchatMenu'>
          <svg class='svg-1' viewBox='0 0 32 32'>
              <g>
                  <path
                      d='M16,2A13,13,0,0,0,8,25.23V29a1,1,0,0,0,.51.87A1,1,0,0,0,9,30a1,1,0,0,0,.51-.14l3.65-2.19A12.64,12.64,0,0,0,16,28,13,13,0,0,0,16,2Zm0,24a11.13,11.13,0,0,1-2.76-.36,1,1,0,0,0-.76.11L10,27.23v-2.5a1,1,0,0,0-.42-.81A11,11,0,1,1,16,26Z'>
                  </path>
                  <path
                      d='M19.86,15.18a1.9,1.9,0,0,0-2.64,0l-.09.09-1.4-1.4.09-.09a1.86,1.86,0,0,0,0-2.64L14.23,9.55a1.9,1.9,0,0,0-2.64,0l-.8.79a3.56,3.56,0,0,0-.5,3.76,10.64,10.64,0,0,0,2.62,4A8.7,8.7,0,0,0,18.56,21a2.92,2.92,0,0,0,2.1-.79l.79-.8a1.86,1.86,0,0,0,0-2.64Zm-.62,3.61c-.57.58-2.78,0-4.92-2.11a8.88,8.88,0,0,1-2.13-3.21c-.26-.79-.25-1.44,0-1.71l.7-.7,1.4,1.4-.7.7a1,1,0,0,0,0,1.41l2.82,2.82a1,1,0,0,0,1.41,0l.7-.7,1.4,1.4Z'>
                  </path>
              </g>
          </svg>
          <svg class='svg-2' viewBox='0 0 512 512'>
              <path
                  d='M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z'>
              </path>
          </svg>
      </label>
      <div class='chatBox'>
        <div class='chatContent'>
            <div class='chatHeader'>
                <svg viewbox='0 0 32 32'>
                    <path
                        d='M24,22a1,1,0,0,1-.64-.23L18.84,18H17A8,8,0,0,1,17,2h6a8,8,0,0,1,2,15.74V21a1,1,0,0,1-.58.91A1,1,0,0,1,24,22ZM17,4a6,6,0,0,0,0,12h2.2a1,1,0,0,1,.64.23L23,18.86V16.92a1,1,0,0,1,.86-1A6,6,0,0,0,23,4Z'>
                    </path>
                    <rect height='2' width='2' x='19' y='9'></rect>
                    <rect height='2' width='2' x='14' y='9'></rect>
                    <rect height='2' width='2' x='24' y='9'></rect>
                    <path
                        d='M8,30a1,1,0,0,1-.42-.09A1,1,0,0,1,7,29V25.74a8,8,0,0,1-1.28-15,1,1,0,1,1,.82,1.82,6,6,0,0,0,1.6,11.4,1,1,0,0,1,.86,1v1.94l3.16-2.63A1,1,0,0,1,12.8,24H15a5.94,5.94,0,0,0,4.29-1.82,1,1,0,0,1,1.44,1.4A8,8,0,0,1,15,26H13.16L8.64,29.77A1,1,0,0,1,8,30Z'>
                    </path>
                </svg>
                <div class='chatTitle'>Silahkan chat dengan tim kami <span>Admin akan membalas dalam beberapa
                        menit</span></div>
            </div>
            <div class='chatText'>
                <span>Halo, Ada yang bisa kami bantu?</span>
                <span class='typing'>...</span>
            </div>
        </div>
  
        <a class='chatStart'
        :href="'https://api.whatsapp.com/send?phone='+tenant.phone+'&text=Hallo Admin CreativeHUB,%20Saya%20ingin%20bertanya'"
           
            rel='nofollow noreferrer' target='_blank'>
            <span>Mulai chat...</span>
        </a>
      </div>
      <footer class="bg-white w-full">
        <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
            <div class="md:flex md:justify-between">
            <div class="mb-6 md:mb-0 mr-4">
                <a href="#" class="flex items-center">
                    <img src="../assets/images/logo.png" class="h-20 mr-3" alt="Creative-Hub Logo" />
                </a>
            </div>
            <div class="grid gap-8 grid-cols-2 lg:grid-cols-4">
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Location</h2>
                    <ul class="text-gray-600  font-medium" v-for="item in locations" :key="item.id">
                        <li class="mb-2" @click="pushRoute(item.id,null)">
                            <p  class="hover:underline">{{item.name}}</p>
                        </li>
                       
                    </ul>
                </div>
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Service</h2>
                    <ul class="text-gray-600  font-medium" v-for="item in categories" :key="item.id">
                        <li class="mb-2" @click="pushRoute(null,item.id)">
                            <p  class="hover:underline">{{item.name}}</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Social Media</h2>
                    <ul class="text-gray-600  font-medium">
                        <li class="mb-2">
                            <a :href="'https://www.instagram.com/Creative-Hub.id/'" target="_blank" class="hover:underline">Instagram</a>
                        </li>
                        <li class="mb-2">
                            <a :href="'https://www.facebook.com/mytelkompropertyofficial'" target="_blank" class="hover:underline">Facebook</a>
                        </li>
                        <li class="mb-2">
                            <a :href="'https://twitter.com/_telkomproperty'" target="_blank" class="hover:underline">Twitter</a>
                        </li>
                        <li class="mb-2">
                            <a :href="'https://goo.gl/maps/Nprc5pUZRjrJ2CtZ7'" target="_blank" class="hover:underline">Maps</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">Kontak</h2>
                    <ul class="text-gray-600  font-medium">
                        <li class="mb-4">
                            <p class="hover:underline text-ellipsis" style="width:200px">
                              {{tenant.head_office_address}}
                            </p>
                            <p class="hover:underline">
                              {{tenant.email}}
                            </p>
                        </li>
                        <li>
                            <p>{{tenant.phone}}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto border-gray-700 lg:my-8" />
        <div class="sm:flex sm:items-center sm:justify-between">
            <span class="text-sm text-gray-500 sm:text-center text-gray-400">© 2023 <a href="#" class="hover:underline">Creative-Hub™</a>. All Rights Reserved.
            </span>
            <div class="flex mt-4 space-x-6 sm:justify-center sm:mt-0">
                <a :href="'https://www.facebook.com/mytelkompropertyofficial'" target="_blank" class="text-gray-500 hover:text-gray-900 text-white">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" /></svg>
                    <span class="sr-only">Facebook page</span>
                </a>
                <a :href="'https://www.instagram.com/Creative-Hub.id/'" target="_blank" class="text-gray-500 hover:text-gray-900 text-white">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" /></svg>
                    <span class="sr-only">Instagram page</span>
                </a>
                <a :href="'https://twitter.com/_telkomproperty'" target="_blank" class="text-gray-500 hover:text-gray-900 text-white">
                    <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
                    <span class="sr-only">Twitter page</span>
                </a>
            </div>
        </div>
        </div>
    </footer>
    </div>
  </div>
</template>
<script>
import vdropdown from '../components/DropdownHeader.vue';
import { webUrl } from '../utils/config'
import AuthService from "@/services/auth.service";
import ApiService from '@/services/api.service';
import productService from "@/services/product.service";
  export default({
    name: "main-layout",
    components:{vdropdown},
    data() {
    return {
      tenant: "",
      isToggle: false,
      url: webUrl,
      isLocation: false,
      locations: [],
      categories: [],
      secret: "123#$%",
      isDropDownLocation:false,
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {

    AuthService.getDataTenant().then(
        (response) => {
          var data = response.data;
          this.tenant = data;
          this.getLocation(data.id);
          this.getCategories(data.id);
          
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );



    },
    methods: {
      getLocation(tenantId){
        ApiService.getDataLocation(tenantId).then(
        (response) => {
            var data = response.data;
            this.locations = data;
            // if(data != null){
            //   localStorage.setItem('locations', JSON.stringify(data));
            // }else{
            //   localStorage.setItem('locations', null);
            // }
            // this.content = response.data;
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        )
    },
    getCategories(tenantId){
      productService.getDataProductCategories(tenantId).then(
        (response) => {
          var data = response.data.data;
          this.categories = data;
          
         
         
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    hadleNav(){
      this.isToggle = !this.isToggle;
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    pushRoute(locationId = null,categoryId = null){
      if(locationId != null){
        this.$router.push({
          name: "product",
          query: {
            location_id: this.$CryptoJS.AES.encrypt(
              locationId.toString(),
              this.secret
            ).toString()
          },
        }).then((response) => {
          location.reload();
        });
       
      }

      if(categoryId != null){
        this.$router.push({
          name: "service",
          query: {
            category_id: this.$CryptoJS.AES.encrypt(
              categoryId.toString(),
              this.secret
            ).toString()
          },
        }).then((response) => {
          location.reload();
        });
        
      }
      
    }
    },
  });
</script>