<template>
  <div id="app">
    <notifications width="400px" />
    <MainLayout v-if="this.$route.meta.layout == 'main-layout'">
      <router-view class="content" />
    </MainLayout>
    <AuthLayout v-else>
      <router-view class="content" />
    </AuthLayout>
  </div>
</template>
<script>
// js global
import $ from "jquery";
import MainLayout from "./layout/MainLayout.vue";
import AuthLayout from "./layout/AuthLayout.vue";
import { defineComponent } from "vue";
import AuthService from "./services/auth.service";
import Swal from "sweetalert2";
import ApiService from '@/services/api.service';
import productService from "@/services/product.service";
export default defineComponent({
  name: "App",
  components: {MainLayout, AuthLayout },
  data() {
      return {
        user: {
          tenant_id: "",
          email: "",
          isLogin: true
        },
      };
    },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    dataTenant() {
      return JSON.parse(localStorage.getItem('tenant'));
    },
  },
  mounted() {
    var user =  this.currentUser;
    var tenant = this.dataTenant;
    
    if(tenant == null){
      AuthService.getDataTenant().then(
      (response) => {
        var data = response.data;
        if(data != null){
          localStorage.setItem('tenant',  JSON.stringify(data));
        }
        
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
    }
    

    if(user != null){
      this.$store.dispatch("auth/me").then( response => {
      }, error => {
        
      
      });
      
    }

   
  if(tenant != null){
      this.getLocation(tenant.id);
      this.getCategories(tenant.id);
    }
    
    
  },
  methods: {
    getLocation(tenantId){
        ApiService.getDataLocation(tenantId).then(
        (response) => {
            var data = response.data;
            
            if(data != null){
              localStorage.setItem('locations', JSON.stringify(data));
            }else{
              localStorage.setItem('locations', null);
            }
            // this.content = response.data;
        },
        (error) => {
            this.content =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
        )
    },
    getCategories(tenantId){
      productService.getDataProductCategories(tenantId).then(
        (response) => {
          var data = response.data.data;
         
          if(data != null){
            localStorage.setItem('categories', JSON.stringify(data));
          }else{
            localStorage.setItem('categories', null);
          }
         
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
});
</script>

